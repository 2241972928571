import React from 'react';
import { Link } from 'react-router-dom';
import tickimage from '../../assets/img/learning/tick.svg';

const CourseMern = () => {
  return (
    <>
      <section class="services-area service-area services-padding">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-12">
              <div class="section-tittle text-center">
                <h2>MERN Full Stack Web Development</h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 bg-warning bg-opacity-10 py-4 text-center">
              <h5>Learn to build web applications on one of the best tech stacks : MongoDB, Express.js, React.js and Node.js - MERN stack.</h5>
            </div>
          </div>


          <div className='row'>

            <div className='col-12'>

              <div class="container-1280px round-container">
                <div class="round-blocks-contain">
                  <div class="w-layout-grid round-column-grid">
                    <div class="round-block-wapper _1">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa495_laptop-code.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">Web Fundamentals (HTML, CSS &amp; JS)</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div><div class="round-block-wapper _2">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa497_lightbulb-on.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">React JS, Redux</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div></div><div class="w-layout-grid round-column-grid">
                    <div class="round-block-wapper _3">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa494_atom-simple%201.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">Node JS, Express</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div>
                    <div class="round-block-wapper _4">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa496_gears.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">MySQL, MongoDB</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div>
                  </div>
                </div>
                <div class="round-text-wrapper">
                  {/* <h2 id="test" class="heading-48px round-heading">Become a MERN Full Stack Developer</h2> */}
                  <div class="round-check-list-container">
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">For college students</div></div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">4 months classroom program</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">2 months internship experience</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Start Date - 8th April</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Timing - Evening - 7-8pm</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Online Instructor led  Classes</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Projects based Learning</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">One-2-one mentorship / Doubt Sessions</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Training Certificate + Internship / Placement Opportunity</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Pay in monthly EMIs after classes</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Total Fee (50% Discount) - <span style={{textDecoration: 'line-through', color: 'red'}}>10,000</span>&nbsp;5,000 </div>
                    </div>
                  </div>
                  <p className='text-primary'>New Batch starting from 22nd April 2024</p>

                  <Link to='/learning/registration/mern'><div class="btn btn-primary">
                    <div class="paragraph-18px colored-button-text">Enroll Now</div>
                  </div>
                  </Link>

                  {/* <div class="button-black-overlay" >
                    </div>
                    <div class="colored-button-gradient-layer " >
                    </div> */}
                  {/* <div class="colored-button-border-layer">
                    </div> */}
                </div></div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CourseMern
