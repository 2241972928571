import React from "react";
import sms from "../../assets/img/products/School_management_system.png";
import "bootstrap/dist/css/bootstrap.min.css";

const SchoolManagement = () => {
  return (
    <>
      <div className="contact-section bg-home3 py-6">
        <div className="container ">
          <div className="row  ">
            <div className="col  ">
              <h1 className="text-start fw-bold  ">
                School <br /> Management System
              </h1>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>

            <div className="col-lg-6 right img-fluid ">
              <div className="pe-0">
                <img
                  src={sms}
                  alt="img"
                  className="img-responsive"
                  style={{ maxWidth: "90%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <section className="bg-light py-5 ">
        <div className="container">
          <div className="row  py-5">
            <div className="col  ">
              <div className="">
                <div className=" " style={{maxWidth: "640px"}}>
                  <span className="text-danger ">Product</span>
                  <h1 className="fw-bold mb-4 ">School Management System</h1>
                  <p className="text-wrap text-justify">
                    Transform your school's management process through our
                    school management system. It is a cloud-based system that
                    eases the management of all the data from student details to
                    exam results and tasks like admission and staff management.
                    It provides a dashboard to access data.
                  </p>
                  <p className="text-wrap text-justify">
                    School administration has the responsibility of managing all
                    the affairs, but it's a tedious task and errors are
                    inevitable in handling huge amounts of data. Our school's
                    management system is a boon for the management people, it
                    makes their work hassle-free and enhances work productivity.
                  </p>
                  <p className="text-wrap text-justify">
                    We curate management systems that suit the requirements of
                    the institute and then digitize their day-to-day processes.
                    With multiple modules and seamless functioning, and secure
                    operations, we deliver the best solution for school
                    management.
                  </p>
                  <p className="text-wrap text-justify">
                    It not only makes the process easy but also saves money by
                    effectively managing the fees and expenses and enabling the
                    management people to track the finances and analyze the
                    expenditures.
                  </p>
                </div>
              </div>
            </div>
            <div className="col mt-3 ms-3" style={{maxWidth: "500px"}}>
              <div className="shadow-lg p-3 bg-white rounded-5 ">
                <h2 className="fw-bold mt-4">Schedule Appointment</h2>
                <p>We here to help you 24/7 with experts</p>
                <form action="" method="">
                  <div className=" my-4">
                    <label>Name</label>
                    <input
                      type="text"
                      required=""
                      className="form-control"
                      name="contact_name"
                      placeholder="Name"
                    />
                  </div>
                  <div className=" mt-4">
                    <label>Email</label>
                    <input
                      type="email"
                      required=""
                      className="form-control"
                      name="contact_email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Phone</label>
                    <input
                      type="tel"
                      required=""
                      className="form-control"
                      name="contact_phone"
                      placeholder="+91"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Your Query</label>
                    <textarea
                      type="Subject"
                      required=""
                      className="form-control"
                      name="message"
                      placeholder="Your Query"
                    ></textarea>
                  </div>

                  {/* <!-- div to show reCAPTCHA --> */}
                  <div
                    className="g-recaptcha mt-4"
                    data-sitekey="6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r"
                  >
                    <div style={{ width: "304px", height: "78px" }}>
                      <div>
                        <iframe
                          title="reCAPTCHA"
                          width="304"
                          height="78"
                          role="presentation"
                          name="a-tnvqec2kjuv"
                          frameborder="0"
                          scrolling="no"
                          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                          src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r&amp;co=aHR0cHM6Ly9jb3Jld2F2ZS5pbzo0NDM.&amp;hl=en&amp;v=1kRDYC3bfA-o6-tsWzIBvp7k&amp;size=normal&amp;cb=rwx58wbrxjrt"
                        ></iframe>
                      </div>
                      <textarea
                        id="g-recaptcha-response-1"
                        name="g-recaptcha-response"
                        className="g-recaptcha-response"
                        style={{
                          width: " 250px",
                          height: "40px",
                          border: "1px solid rgb(193, 193, 193)",
                          margin: " 10px 25px",
                          padding: " 0px",
                          resize: "none",
                          display: " none",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <br />

                  <div className=" d-flex justify-content-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg mx-1 "
                      style={{ width: "280px" }}
                      name="submit_btn"
                      value="Submit Now"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*     */}
      <section className="section px-4 py-5 bg-dark">
        <div className="container">
          <div className=" mt-5 ">
            <h2 className="display-5 fw-bold text-white">
              Benefits of School management system
            </h2>
            <p className="opacity-20 py-4">
              Our school management system is advanced multi-capacity software
              that enables schools and educational institutes to streamline
              their processes and helps them manage each and every aspect of
              management. From fee collection to designing curriculum, tracking
              attendance, and generating payrolls, all can be done easily
              through our robust school management system. It is packed with
              features that transform traditional school management functions
              into a smart and error-free system.
            </p>
          </div>
          <div className="row gap-0 row-gap-5 py-5">
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content ">
                  <h5 className="text-white "> Effective Management</h5>
                  <p>Digitized data management</p>
                  <p>Easy data analysis&nbsp;</p>
                  <p>Student profile tracking</p>
                  <p>Graphic report generation</p>
                  <p>Monitor finances</p>
                  <p>Access data in multiple formats</p>
                  <p>Track staff activities</p>
                  <p>Simplify admission process</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid active">
                <div className="content">
                  <h5 className="text-white"> Improves teaching</h5>
                  <p>AI-assisted lesson plans</p>
                  <p>Track students performance</p>
                  <p>Analyze assessments and tests.</p>
                  <p>Understand learning gaps</p>
                  <p>Attendance management</p>
                  <p>Activity records in the classroom</p>
                  <p>Share educational resources</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content">
                  <h5 className="text-white"> Go Digital</h5>
                  <p>Online payments gateway</p>
                  <p>Online receipt generation</p>
                  <p>Fill Admission form online</p>
                  <p>Easy access to prospectus and curriculum</p>
                  <p>Access results&nbsp;</p>
                  <p>Convenient eLearning</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className=" py-5 bg-light">
        <div className="container ">
          <div className="col-lg-12 ">
            <h1 className="fw-bold py-5 ms-2 ">Modules</h1>
          </div>
          <div className="row mb-3 d-flex flex-wrap">
            <div className=" col-lg-6 m-3  p-3 bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Administration</h3>
              </div>
              <p className="">
                Just through this single module the administrators can easily
                manage the central database and control the activities and keep
                track of every process. This is a major management module that
                helps in maintaining the finances most efficiently. Also, the
                managers can track the school inventory and access records of
                all the items present. Our cloud-based school management system
                is one of the best software that ensures transparency and
                accuracy.
              </p>
            </div>

            <div className=" col  m-3  p-3   bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3"> Student</h3>
              </div>
              <p>
                This module contains all the information regarding the students
                making it easy to find all the details in one place. It stores
                student information, classwise performance details, and
                attendance all organized into groups and can be fetched with
                just one click. It has user-based access and is interlinked with
                other modules so provides hassle-free information.
              </p>
            </div>
          </div>
          <div className="row  mb-3 d-flex flex-wrap">
            <div className=" col-lg-6 m-3  p-3 bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Fees</h3>
              </div>
              <p>
                Our school management system makes fee collection and management
                easy. This module stores details about fee payments, late fees,
                fees due, and other related information. The module is
                integrated with accounting to ensure efficient management of
                funds. It has automated reminders to send SMS to parents for fee
                submission and helps schools easily trace defaulters.
              </p>
            </div>
            <div className="col  m-3  p-3  bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3"> Attendance</h3>
              </div>
              <p>
                This eliminates the need for the old pen and register attendance
                and enables quick and efficient attendance recording. It helps
                in digitally storing student attendance and enables easy access
                and report generation. The management can monitor absentee's
                records and can receive and approve leaves. Our cloud-based
                software comes with advanced features and can be integrated with
                biometric devices too. It provides organized and systematically
                stored data at any time. Safe and secured data storage Easy
                recording Organized data logs
              </p>
            </div>
          </div>
          <div className="row  mb-3 d-flex flex-wrap">
            <div className=" col-lg-6 m-3  p-3 bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3"> Exams</h3>
              </div>
              <p>
                Conducting exams will be easy in this module as it enables
                schools to plan and schedule exams. It also helps in creating
                accurate and well-designed report cards. Through this schools
                can generate results and mark sheets more efficiently and in
                less time. Students and their parents can also access markets
                and results through this module. It also provides performance
                analysis reports that help in understanding students' potential
                areas of strengths and weaknesses.
                <ul>
                  <li>Reliable results in no time</li>
                  <li>Digital mark sheets </li>
                  <li>Easy to use</li>
                </ul>
              </p>
            </div>
            <div className="col  m-3  p-3  bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Lesson plans</h3>
              </div>
              <p>
                This module helps in curating easy lesson plans with all the
                information about the syllabus and topics in one place. Teachers
                can strategize lesson plans based on class performance and can
                easily mark the progress. The modules teachers manage the
                syllabus for the best results.
                <ul>
                  <li>Manage lesson plans</li>
                  <li>Manage syllabus</li>
                  <li>List topics</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row  mb-3 d-flex flex-wrap">
            <div className=" col-lg-6 m-3  p-3 bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Human resource</h3>
              </div>
              <p>
                Human resource management is made easy through our exceptional
                software which allows schools to manage every aspect of their
                teaching and non-teaching staff through this module. It stores
                information and details about the staff, records staff
                attendance, and assists in leave approvals, and other such
                management processes. It also helps in payroll management and
                stores payment transfer data to ensure accuracy in salary
                management. The software also helps online recruitment where the
                candidates can apply easily and the school can screen and select
                the right candidate.
              </p>
            </div>
            <div className="col  m-3  p-3  bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Online classes</h3>
              </div>
              <p>
                We help schools in transforming their education system through
                our software that provides the feature of online classes that
                allows more accessible and convenient learning. The student can
                join online classes, watch recorded lectures, and can
              </p>
            </div>
          </div>
          <div className="row  mb-3 d-flex flex-wrap">
            <div className=" col-lg-6 m-3  p-3 bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Communication</h3>
              </div>
              <p>
                This module enables school management can stay connected with
                the staff, student, and their parents. The AI-powered software
                allows for generating and sending automated SMS and emails to
                teachers and parents. The school can easily manage and update
                information on the notice board through this module.
                <ol>
                  <li>Send SMS</li>
                  <li>Send email</li>
                  <li>Notice board</li>
                </ol>
              </p>
            </div>
            <div className=" col  m-3  p-3  bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Download Resources</h3>
              </div>
              <p>
                This module allows users to download resources like study
                material, assignments, and syllabi. Teachers and management
                staff can upload the content with ease, making it accessible for
                all. The software helps in the dissemination of resources and
                students can find all the resources in one place.
                <ol>
                  <li>Study material</li>
                  <li>Upload content</li>
                  <li>Assignment </li>
                  <li>Syllabus</li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      </section>
      
      
      {/*  */}

      <div className="  bg-info bg-gradient px-4 py-5  text-center">
        <p className="text-success-emphasis mb-2">Let's Work Together</p>
        <h1 className="display-5 fw-bold text-info text-body-emphasis py-5">
          Need A Succesful Project?
        </h1>
        <div className="col-lg-6 mx-auto py-2">
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button type="button" className="btn btn-primary btn-lg px-5 gap-3">
              Estimate project
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolManagement;
