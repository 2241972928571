import React from 'react';
import angular from '../assets/img/tech/angular.png';
import reactlogo from '../assets/img/tech/react.png';
import java from '../assets/img/tech/java.png';
import nextjs from '../assets/img/tech/nextjs.png';
import node from '../assets/img/tech/node.png';
import nuxt from '../assets/img/tech/nuxt.png';
import python from '../assets/img/tech/python.png';
import vue from '../assets/img/tech/vue.png';
import aws from '../assets/img/tech/aws.png';
import googlecloud from '../assets/img/tech/googlecloud.png';
import mongodb from '../assets/img/tech/mongodb.png';
import mysql from '../assets/img/tech/mysql.png';

import acko from '../assets/img/client/acko.png';
import dream11 from '../assets/img/client/dream11.png';
import ge from '../assets/img/client/ge.png';
import maersk from '../assets/img/client/maersk.png';
import nvidia from '../assets/img/client/nvidia.png';
import pinelabs from '../assets/img/client/pinelabs.jpg';
import upgrad from '../assets/img/client/upgrad.png';
import zoomcar from '../assets/img/client/zoomcar.png';

const Services = () => {
    return (
        <>
            <section class="services-area service-area services-padding">
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-6">
                            <div class="section-tittle text-center">
                                <h2>HashedBit Services</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <div class="services-caption text-center mb-30">
                                <div class="service-icon">
                                    <span class="flaticon-businessman"></span>
                                </div>
                                <div class="service-cap">
                                    <h4><a>Brand Building</a></h4>
                                    <p>The only way to be truly satisfied is to do what you believe is great work.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <div class="services-caption active text-center mb-30">
                                <div class="service-icon">
                                    <span class="flaticon-pay"></span>
                                </div>
                                <div class="service-cap">
                                    <h4><a>Digital Solutions</a></h4>
                                    <p>Digital Solutions for all business domains.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <div class="services-caption text-center mb-30">
                                <div class="service-icon">
                                    <span class="flaticon-plane"></span>
                                </div>
                                <div class="service-cap">
                                    <h4><a>Web Development</a></h4>
                                    <p>All Web development services including CMS and E-Commerce.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <div class="services-caption text-center mb-30">
                                <div class="service-icon">
                                    <span class="flaticon-businessman"></span>
                                </div>
                                <div class="service-cap">
                                    <h4><a>Strategy Analysis</a></h4>
                                    <p>The requirement for an user experience is to meet the needs of customer.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <div class="services-caption text-center mb-30">
                                <div class="service-icon">
                                    <span class="flaticon-pay"></span>
                                </div>
                                <div class="service-cap">
                                    <h4><a>User Experience</a></h4>
                                    <p>The only way to be truly satisfied is to do what you believe is great work.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <div class="services-caption text-center mb-30">
                                <div class="service-icon">
                                    <span class="flaticon-plane"></span>
                                </div>
                                <div class="service-cap">
                                    <h4><a>Business Innovation</a></h4>
                                    <p>Responsive design is an approach that suggests the design to the behavior.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="our-customer pt-200 pb-100 bg-curve">
                <div class="container-fluid">
                    <div class="our-customer-wrapper">
                        <div class="row d-flex justify-content-center">
                            <div class="col-xl-8">
                                <div class="section-tittle text-center">
                                    <h2>Our Technologies</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row our-technologies">
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={angular} alt="" className='img-fluid' />
                                    <p>AngularJS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={reactlogo} alt="" className='img-fluid' />
                                    <p>React</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={nextjs} alt="" className='img-fluid' />
                                    <p>NextJS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={node} alt="" className='img-fluid' />
                                    <p>NodeJS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={vue} alt="" className='img-fluid' />
                                    <p>VueJS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={nuxt} alt="" className='img-fluid' />
                                    <p>NuxtJS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={java} alt="" className='img-fluid' />
                                    <p>Java</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={python} alt="" className='img-fluid' />
                                    <p>Python</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={aws} alt="" className='img-fluid' />
                                    <p>AWS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={googlecloud} alt="" className='img-fluid' />
                                    <p>Google Cloud</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={mongodb} alt="" className='img-fluid' />
                                    <p>MongoDB</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={mysql} alt="" className='img-fluid' />
                                    <p>MySQL</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div class="our-customer pt-200 pb-100 bg-curve">
                <div class="container-fluid">
                    <div class="our-customer-wrapper">
                        <div class="row d-flex justify-content-center">
                            <div class="col-xl-8">
                                <div class="section-tittle text-center">
                                    <h2>Our Clients</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row our-clients">
                            <div class="col-3">
                                <div className='client-div'>
                                    <img src={acko} alt="" className='img-fluid' />
                                    <p>Acko</p>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className='client-div'>
                                    <img src={dream11} alt="" className='img-fluid' />
                                    <p>Dream11</p>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className='client-div'>
                                    <img src={ge} alt="" className='img-fluid' />
                                    <p>GE</p>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className='client-div'>
                                    <img src={maersk} alt="" className='img-fluid' />
                                    <p>Maersk</p>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className='client-div'>
                                    <img src={nvidia} alt="" className='img-fluid' />
                                    <p>Nvidia</p>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className='client-div'>
                                    <img src={pinelabs} alt="" className='img-fluid' />
                                    <p>PineLabs</p>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className='client-div'>
                                    <img src={upgrad} alt="" className='img-fluid' />
                                    <p>upGrad</p>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className='client-div'>
                                    <img src={zoomcar} alt="" className='img-fluid' />
                                    <p>Zoomcar</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services
