import React from 'react';
import { Link } from 'react-router-dom';
import tickimage from '../../assets/img/learning/tick.svg';

const MBAPaidInternship = () => {
  return (
    <>
      <section class="services-area service-area services-padding">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-12">
              <div class="section-tittle text-center">
                <h2>Join us as Business Development Executive</h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 bg-warning bg-opacity-10 py-4 text-center">
            <h5>Business Development</h5>
            </div>
          </div>


          <div className='row'>

            <div className='col-12'>

              <div class="container-1280px round-container">
                <div class="round-blocks-contain">
                  <div class="w-layout-grid round-column-grid">
                    <div class="round-block-wapper _1">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa495_laptop-code.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">Lead Generation</div>
                      {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                    </div><div class="round-block-wapper _2">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa497_lightbulb-on.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">Sales & Marketing</div>
                      {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                    </div></div><div class="w-layout-grid round-column-grid">
                    <div class="round-block-wapper _3">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa494_atom-simple%201.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">Client Relations</div>
                      {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                    </div>
                    <div class="round-block-wapper _4">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa496_gears.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">Strategy and Campaigning</div>
                      {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                    </div>
                  </div>
                </div>
                <div class="round-text-wrapper">
                  {/* <h2 id="test" class="heading-48px round-heading">Get Your dream job</h2> */}
                  <div class="round-check-list-container">
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Freshers/experienced - IT Domain Knowledge preferred</div></div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">2 Months Paid Internship - Stipend 5000 Per Month</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">PPO/Full Time Opportunity on satisfactory performance</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Remote / Work from Home Opportunity</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Timing - Normal Office Hours</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Mandatory - Persoanl laptop & Basic Computer skills</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Passionate for Business Development works</div>
                    </div>
                    
                    {/* <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Technologies - MERN, JavaScript, React, PHP, Laravel, Wordpress, Mobile Applications, React Native</div>
                    </div> */}

                    <div class="round-check-list-row">
                      <h4>Selection Process</h4>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Step 1 - CV Shortlisting</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Step 2 - Screening Interview</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Step 3 - Business Development Screening Task</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Step 4 - Offer Letter & Onboarding</div>
                    </div>
                  </div>

                  <Link to='/learning/registration/mbapaidinternship'><div class="btn btn-primary">
                    <div class="paragraph-18px colored-button-text">Enroll Now</div>
                   
                  </div>
                  </Link>
                  
                </div></div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MBAPaidInternship;
