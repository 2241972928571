import React from "react";
import emp from "../../assets/img/products/employee.png";
import "bootstrap/dist/css/bootstrap.min.css";

const EmployeeManagement = () => {
  return (
    <>
      <div className="contact-section bg-home3 py-6">
        <div className="container my-5">
          <div className="row  ">
            <div className="col  ">
              <h1 className="text-start fw-bold  ">
                Employee <br /> Management System
              </h1>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <div className="col-lg-6 right img-fluid ">
              <div className="pe-0">
                <img
                  src={emp}
                  alt="img"
                  className="img-responsive"
                  style={{ maxWidth: "90%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-light py-5 ">
        <div className="container">
          <div className="row  ps-5 pe-3">
            <div className="col  ">
              <div className="">
                <div className=" ">
                  <span className="text-danger ">Product</span>
                  <h1 className="fw-bold mb-4 ">Employee Management System</h1>
                  <p className=" text-justify" style={{maxWidth: " 600px"}}>
                    Our HRMS is advanced software that provides seamless human
                    resource management and smooth workflow. It is a unified
                    software that automates day-to-day management and simplifies
                    the process. It ensures flawless work and leverages the
                    human resources potential to establish a more efficient
                    administrative system.
                    Human resource management is one of the keys to the success
                    of a business. Bad or inefficient HR management can lead to
                    the failure of a business. Our HR management software is one
                    way you can improve the efficiency of your HR management.
                    From sales to finance, the HRMS is not only useful for the
                    management team but also comes with integrated software that
                    helps almost every department. The AI-powered software
                    enables efficient and cost-effective management with high
                    accuracy.
                  </p>
                  
                  
                </div>
              </div>
            </div>
            <div className="col mt-3 ms-5 " style={{ maxWidth: " 500px" }}>
              <div className="shadow-lg p-3 bg-white rounded-5 ">
                <h2 className="fw-bold mt-4">Schedule Appointment</h2>
                <p>We here to help you 24/7 with experts</p>
                <form action="" method="">
                  <div className=" my-4">
                    <label>Name</label>
                    <input
                      type="text"
                      required=""
                      className="form-control"
                      name="contact_name"
                      placeholder="Name"
                    />
                  </div>
                  <div className=" mt-4">
                    <label>Email</label>
                    <input
                      type="email"
                      required=""
                      className="form-control"
                      name="contact_email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Phone</label>
                    <input
                      type="tel"
                      required=""
                      className="form-control"
                      name="contact_phone"
                      placeholder="+91"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Your Query</label>
                    <textarea
                      type="Subject"
                      required=""
                      className="form-control"
                      name="message"
                      placeholder="Your Query"
                    ></textarea>
                  </div>

                  {/* <!-- div to show reCAPTCHA --> */}
                  <div
                    className="g-recaptcha mt-4"
                    data-sitekey="6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r"
                  >
                    <div style={{ width: "304px", height: "78px" }}>
                      <div>
                        <iframe
                          title="reCAPTCHA"
                          width="304"
                          height="78"
                          role="presentation"
                          name="a-tnvqec2kjuv"
                          frameborder="0"
                          scrolling="no"
                          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                          src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r&amp;co=aHR0cHM6Ly9jb3Jld2F2ZS5pbzo0NDM.&amp;hl=en&amp;v=1kRDYC3bfA-o6-tsWzIBvp7k&amp;size=normal&amp;cb=rwx58wbrxjrt"
                        ></iframe>
                      </div>
                      <textarea
                        id="g-recaptcha-response-1"
                        name="g-recaptcha-response"
                        className="g-recaptcha-response"
                        style={{
                          width: " 250px",
                          height: "40px",
                          border: "1px solid rgb(193, 193, 193)",
                          margin: " 10px 25px",
                          padding: " 0px",
                          resize: "none",
                          display: " none",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <br />

                  <div className=" d-flex justify-content-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg mx-1 "
                      style={{ width: "280px" }}
                      name="submit_btn"
                      value="Submit Now"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section px-4 py-5 bg-dark">
        <div className="container">
          <div className=" mt-5 ">
            <h2 className="display-5 fw-bold text-white">
              Unlock the power of cutting-edge HR management system
            </h2>
          </div>
          <div className="row gap-0 row-gap-5 py-5">
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content " style={{maxWidth: " 380px"}} >
                  <h5 className="text-white "> Get 360 Degree solution</h5>
                  <p className="text-justify">
                    Manage everyday HR processes and track performance with a
                    cloud-based modern HRMS. It offers comprehensive management
                    solutions, easy interactions, and automated hiring and
                    screening while saving time and managing expenses
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid active">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> AI backed working</h5>
                  <p>
                    The HRMS is a set of advanced solutions that enable the
                    managing team to digitize processes.HRs leverage the power
                    of AI-driven intelligent and insightful reports to
                    effectively manage and develop their workforce.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Reduce HR management Cost</h5>
                  <p>
                    Our cloud-based HRMS can significantly bring down HR costs
                    by reducing the dependence on IT and thus reducing the
                    number of employees required to manage it.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white" >
                    Ensure timely project completion
                  </h5>
                  <p>
                    Manage projects effectively with project management features
                    to allocate tasks, project analytics, team management and
                    get real-time project updates.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white">
                    {" "}
                    Features of our HR management software
                  </h5>
                  <p>
                    This modern HRMS enables organizations to create a flexible
                    and adaptable management process while offering easy
                    communication and an improved employee experience
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Employee Management</h5>
                  <p>
                    Manage, develop and engage your team with a transparent and
                    secure system. A good employee management system is crucial
                    for any business with the help of our HRMS you can
                    effectively manage and keep records throughout the employee
                    lifecycle. The software contains employee databases and
                    records and maintains their personal and professional
                    information
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Leave Management</h5>
                  <p>
                    Manage leave, and get comprehensive details on the dashboard
                    to comply with the leave policy. Our HRMS enables
                    hassle-free leave and time off management with an advanced
                    time-tracking system to check employee availability. It
                    comes with an informative dashboard that provides reports
                    and analytics and AI-backed support.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content"style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Attendance Management</h5>
                  <p>
                    Track attendance, schedule tactical shifts, and make
                    multiple attendance modes possible. It assists managers in
                    managing attendance, scheduling, and staffing needs by
                    providing real-time information and tracking employees' in
                    and out timing. The HRMS also notifies employees when their
                    schedules and deployments change, or when there is a
                    staffing shortage.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Payroll Management</h5>
                  <p>
                    Simplify loan approval and EMIs, integrate payroll, and set
                    up pay groups. The HRMS makes payroll management more
                    efficient by helping managers and employers see an accurate
                    picture of their employees’ finances, including salary,
                    advances, bonuses, and deductions. It is integrated with
                    various financial tools that enable smooth financial
                    workflow.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" p-5   ">
        <div className="container">
          <div className="row ps-4">
            <h4 className="text-danger"> why choose us</h4>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>360 Approach</h4>
              <p className="text-wrap text-justify">
                End-to-end hospital management solutions with dynamic modules to
                meet every need for efficient hospital operations and
                comprehensive management.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Tested and Proven</h4>
              <p>
                Corewave’s HMS is developed and tested by highly experienced
                professionals to ensure flawless and reliable functioning.
                Trusted by reputed healthcare providers we are best-in-class
                management software
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Highly Scalable</h4>
              <p>
                A scalable software to provide you best solutions as you grow.
                Our solution expands along with your working needs.{" "}
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>User friendly</h4>
              <p>
                Designed to suit customer needs, our HMS is flexible and easy to
                use. Functions are included while keeping the requirements in
                mind to provide maximum efficiency.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Cost effective</h4>
              <p>
                A highly useful and robust software at the most reasonable cost.
                We aim to provide the best technology solutions at the best
                prices.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Join the transformation</h4>
              <p className="text-justify">
                We are eager to support the crucial process of digital
                transformation within your healthcare organization. For all your
                software requirements we are here to provide you most relevant
                solutions
              </p>
            </div>
            <h4 className="my-3">Customise solution</h4>
            <h4 className="my-3">Quick execution</h4>
            <h4 className="my-3">Complete the Test and Display</h4>
            <h4 className="mt-3 mb-5">Speedy Setup</h4>
          </div>
        </div>
      </section>

      <div className="  bg-info bg-gradient px-4 py-5  text-center">
        <p className="text-success-emphasis mb-2">Let's Work Together</p>
        <h1 className="display-5 fw-bold text-info text-body-emphasis py-5">
          Need A Succesful Project?
        </h1>
        <div className="col-lg-6 mx-auto py-2">
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button type="button" className="btn btn-primary btn-lg px-5 gap-3">
              Estimate project
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeManagement;
