import React, { useEffect, useState } from 'react';
import blogImage from '../assets/img/blog/single_blog_1.png';
import { useParams } from 'react-router';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import RecentBlogs from './blogsubpages/RecentBlogs';
import BlogCategories from './blogsubpages/BlogCategories';

const Blog = () => {

    const { blogid } = useParams();
    const [blogsData, setBlogsData] = useState([]);
    const [categories, setcategories] = useState([{ name: '', displayname: '', count: 0 }, { name: '', displayname: '', count: 0 }]);
    const [recentPosts, setRecentPosts] = useState([]);

    const navigate = useNavigate();

    const fetchBlogs = async () => {
        const url = process.env.REACT_APP_API_URL + 'blog/fetchblogs/' + '0';

        try {
            //const response = await fetch(url);
            const response = await axios.get(url);
            //console.log(response.data);
            setBlogsData(response.data)
        }
        catch (err) {
            console.log('Error In API Call - ', err)
        }
    }

    const fetchCategories = async () => {
        const url = process.env.REACT_APP_API_URL + 'category/fetchallcategories';
        try {
            const response = await axios.get(url);
            //console.log(response.data);
            setcategories(response.data)
        }
        catch (err) { console.log('Error In API Call - ', err) }
    }

    const fetchRecentPosts = async () => {
        const url = process.env.REACT_APP_API_URL + 'blog/recentblogs';
        try {
            const response = await axios.get(url);
            //console.log(response.data);
            setRecentPosts(response.data)
        }
        catch (err) { console.log('Error In API Call - ', err) }
    }

    const showBlogPage = (srnum, title) => {
        window.localStorage.setItem('blogid', srnum);
        //redirect to blog page with title in url
        navigate(`/blog/${title}`)
    }

    useEffect(() => {
        fetchBlogs();
        fetchCategories();
        fetchRecentPosts();
    }, [])

    // useEffect(() => {
    //     console.log('blogsData', blogsData)
    // })

    return (

        <>

            <div class="services-area">
                <div class="container">

                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-8">
                            <div class="section-tittle text-center mb-80">
                                <h2>Thoughts, Ideas and Tech Articles​</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="blog_area section-paddingr">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 mb-5 mb-lg-0">
                            <div class="blog_left_sidebar">
                                {
                                    blogsData && blogsData.map((item, index) => (
                                        <article class="blog_item">
                                            <div class="blog_item_img">
                                                {/* <img class="card-img rounded-0" src={blogImage} alt="" /> */}

                                                {/* Featured Image */}
                                                {/* <img src={require(`../assets/img/blog/articles/${item.featuredimage}`)} class="img-fluid" alt="post" /> */}
                                                {/* <Link to={`/blog/${item.srno}`} class="blog_item_date">
                                                    <h3>15</h3>
                                                    <p>Jan</p>
                                                </Link> */}
                                            </div>

                                            <div class="blog_details">
                                                <button class="d-inline-block" onClick={() => showBlogPage(item.srno, item.posttitle)} className='btn btn-warning-outline' >
                                                    <h2>{item.postheading}</h2>
                                                </button>
                                                <p className='blogs-content'>
                                                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                                </p>
                                                <button class="d-inline-block" onClick={() => showBlogPage(item.srno, item.posttitle)} className='btn btn-warning' >
                                                    <div>Read more ...</div>
                                                </button>
                                                <div class="blog-info-link mt-4">
                                                    <span>{item.category}</span>
                                                </div>
                                            </div>
                                        </article>
                                    ))
                                }

                                {/* <nav class="blog-pagination justify-content-center d-flex">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a href="#" class="page-link" aria-label="Previous">
                                                <i class="ti-angle-left"></i>
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a href="#" class="page-link">1</a>
                                        </li>
                                        <li class="page-item active">
                                            <a href="#" class="page-link">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a href="#" class="page-link" aria-label="Next">
                                                <i class="ti-angle-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="blog_right_sidebar">
                                {/* <aside class="single_sidebar_widget search_widget">
                                    <form action="#">
                                        <div class="form-group">
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control" placeholder='Search Keyword'
                                                    onfocus="this.placeholder = ''"
                                                    onblur="this.placeholder = 'Search Keyword'" />
                                                <div class="input-group-append">
                                                    <button class="btns" type="button"><i class="ti-search"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn"
                                            type="submit">Search</button>
                                    </form>
                                </aside> */}

                                <BlogCategories categories={categories} />

                                <RecentBlogs recentPosts={recentPosts} />

                                {/* <aside class="single_sidebar_widget tag_cloud_widget">
                                    <h4 class="widget_title">Tag Clouds</h4>
                                    <ul class="list">
                                        <li>
                                            <a href="#">project</a>
                                        </li>
                                        <li>
                                            <a href="#">love</a>
                                        </li>
                                        <li>
                                            <a href="#">technology</a>
                                        </li>
                                        <li>
                                            <a href="#">travel</a>
                                        </li>
                                        <li>
                                            <a href="#">restaurant</a>
                                        </li>
                                        <li>
                                            <a href="#">life style</a>
                                        </li>
                                        <li>
                                            <a href="#">design</a>
                                        </li>
                                        <li>
                                            <a href="#">illustration</a>
                                        </li>
                                    </ul>
                                </aside> */}


                                {/* <aside class="single_sidebar_widget instagram_feeds">
                                    <h4 class="widget_title">Instagram Feeds</h4>
                                    <ul class="instagram_row flex-wrap">
                                        <li>
                                            <a href="#">
                                                <img class="img-fluid" src="assets/img/post/post_5.png" alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img class="img-fluid" src="assets/img/post/post_6.png" alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img class="img-fluid" src="assets/img/post/post_7.png" alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img class="img-fluid" src="assets/img/post/post_8.png" alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img class="img-fluid" src="assets/img/post/post_9.png" alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img class="img-fluid" src="assets/img/post/post_10.png" alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </aside>


                                <aside class="single_sidebar_widget newsletter_widget">
                                    <h4 class="widget_title">Newsletter</h4>

                                    <form action="#">
                                        <div class="form-group">
                                            <input type="email" class="form-control" onfocus="this.placeholder = ''"
                                                onblur="this.placeholder = 'Enter email'" placeholder='Enter email' required />
                                        </div>
                                        <button class="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn"
                                            type="submit">Subscribe</button>
                                    </form>
                                </aside> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blog
