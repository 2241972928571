import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from "formik";
import loadImage from '../assets/img/giphy.gif';
import { Link } from 'react-router-dom';

const Career = () => {

    const [apistatus, setApistatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);

    const [careerFormStatus, setCareerFormStatus] = useState(false);

    const formikInitialValues = {
        name: "",
        email: "",
        mobile: "",
        whatsapp_mobile: "",
        city: "",
        hometown: "",
        college: "",
        university: "",
        passing_year: "",
        branch: "",
        linkedinid: "",
        githubid: "",
        referredby: "",
        currentcompany: "",
        yearsOfExperience: "",
        currentDesignation: "",
        noticePeriod: "",
        currentSalary: "",
        skills: "",
        dob: "",
        workLocation: "",
        typePartFull: "",
        typeFullIntern: "",
        jobType: "",
    }
    //uploadCV

    const submitCareer = async (values) => {
        setLoader(true);
        console.log(values);
        const url = process.env.REACT_APP_API_URL + 'career/register';

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            });
            //const response = await axios.post(url, values);
            //console.log(response);

            if (response.status === 200) {
                setApistatus(true);
                setLoader(false);
                return {
                    error: false
                }
            }
            else {
                setError(true);
                setLoader(false);
                return {
                    error: true
                }
            }
        }
        catch (err) {
            setError(true);
            setLoader(false);
            console.log('Error In API Call - ', err)
        }
    }

    return (
        <>
            <div className="services-area">
                <div className="container">

                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-tittle text-center mb-80">
                                <h2>Join Us​</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <section className="contact-section light-green-bg py-4">
                <div className="container">
                    <div className="row">
                        <div className='col-12 rounded px-3 py-3'>
                            {/* <div className="col-12">
                                <h2 className="contact-title text-danger">Register for Paid Business Development Internship</h2>
                            </div> */}
                            {/* <div className="col-12">
                                <h5 className="contact-title">Intership process will start from 2nd week of January.</h5>
                            </div> */}
                            {/* <div className="col-12">
                                <p>
                                    Step 1 - Register on the link below.<br></br>
                                    Step 2 - You will be contacted for further process like interview and task.<br></br>
                                    <br></br>
                                </p>
                            </div>

                            <div className="col-12">
                                <p>Note - Please, do not register multiple times. You will be connected within a week.</p>
                                <br></br>
                            </div> */}

                            <div className="col-12">
                                <Link to='/learning/mbapaidinternship' class="btn btn-success">
                                    <div class="paragraph-18px colored-button-text">Register for Interview based Paid Business Development Internship</div>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </section>




            <section className="contact-section light-blue-bg py-4">
                <div className="container">
                    <div className="row">
                        <div className='col-12 rounded px-3 py-3'>
                            {/* <div className="col-12">
                                <h2 className="contact-title text-danger">Register for Online Exam based Tech Internship</h2>
                            </div> */}
                            {/* <div className="col-12">
                                <h5 className="contact-title">Intership process will start from 2nd week of January.</h5>
                            </div> */}
                            {/* <div className="col-12">
                                <p>
                                    Step 1 - Register on the link below.<br></br>
                                    Step 2 - You will confirmation and online exam details on mail.<br></br>
                                    Step 3 - Take the online exam.<br></br>
                                    Step 4 - As per your results, you will offered internship.<br></br><br></br>
                                </p>
                            </div>

                            <div className="col-12">
                                <p>Note - Please, do not register multiple times.</p>
                                
                                <br></br>
                            </div> */}

                            {/* <div className="col-12">
                                <a href="https://examhub.hashedbit.com/useradd" target='_blank' class="btn btn-primary">
                                    <div class="paragraph-18px colored-button-text">Enroll Now</div>
                                </a>
                            </div> */}

                            <div className="col-12">
                                <Link to='/learning/internship' class="btn btn-primary">
                                    <div class="paragraph-18px colored-button-text">Register for Online Exam based Tech Internship</div>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </section>



            <div className="services-area">
                <div className="container">

                    <div className="row d-flex justify-content-center">
                        {/* <div className="col-lg-8">
                            <div className="section-tittle text-center mb-80">
                                <h2>Join Us​</h2>
                            </div>
                        </div> */}

                        <div className="col-12 mb-80">
                            <button onClick={() => setCareerFormStatus(!careerFormStatus)} class="btn btn-warning">
                                <div class="paragraph-18px colored-button-text">{careerFormStatus ? 'Hide': 'Interested in Open Positions - Tech/HR/BDE'}</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        {careerFormStatus &&
            (<section className="contact-section">
                <div className="container">

                    <div className="row">
                        <div className="col-12">
                            <h2 className="contact-title">Please, register here. we will get back if we have any matching opportunity.</h2>
                        </div>



                        <div className='col-12 bg-info bg-opacity-10 rounded'>

                            <Formik
                                initialValues={formikInitialValues}
                                onSubmit={async (values, { resetForm }) => {
                                    //console.log(values);
                                    const { error } = await submitCareer(values);
                                    if (!error) {
                                        resetForm();
                                    };
                                }}
                            >
                                <Form>
                                    <br></br>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Name</label>
                                        <div className='col-sm-8'>
                                            <Field name="name" type="text" className="form-control" placeholder="Enter Full Name" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Email</label>
                                        <div className='col-sm-8'>
                                            <Field name="email" type="email" className="form-control" placeholder="Enter Gmail" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Mobile</label>
                                        <div className='col-sm-8'>
                                            <Field name="mobile" type="text" className="form-control" placeholder="Enter Mobile No" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">WhatsApp Mobile</label>
                                        <div className='col-sm-8'>
                                            <Field name="whatsapp_mobile" type="text" className="form-control" placeholder="Enter WhatsaApp No" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Date of Birth</label>
                                        <div className='col-sm-8'>
                                            <Field name="dob" type="date" className="form-control" placeholder="" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Present Address</label>
                                        <div className='col-sm-8'>
                                            <Field name="city" type="text" className="form-control" placeholder="Enter Present Address" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Permanent Address</label>
                                        <div className='col-sm-8'>
                                            <Field name="hometown" type="text" className="form-control" placeholder="Enter Permanent Address" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">College</label>
                                        <div className='col-sm-8'>
                                            <Field name="college" type="text" className="form-control" placeholder="College Name" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">University</label>
                                        <div className='col-sm-8'>
                                            <Field name="university" type="text" className="form-control" placeholder="University Name" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Passing Year</label>
                                        <div className='col-sm-8'>
                                            <Field name="passing_year" type="text" className="form-control" placeholder="College Passing Year" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Branch / Stream</label>
                                        <div className='col-sm-8'>
                                            <Field name="branch" type="text" className="form-control" Enter placeholder="Branch/Stream like B Tech/BCA/MCA/M Tech and Branch" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Linkedin Profile URL</label>
                                        <div className='col-sm-8'>
                                            <Field name="linkedinid" type="text" className="form-control" placeholder="Your Linkedin Profile URL (if any)" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Github Profile URL</label>
                                        <div className='col-sm-8'>
                                            <Field name="githubid" type="text" className="form-control" placeholder="Your Github Profile URL (if any)" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Where Did You Hear About Us</label>
                                        <div className='col-sm-8'>
                                            <Field name="referredby" type="text" className="form-control" placeholder="LinkedIn/Instagram/WhatsApp/Others" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Current Company</label>
                                        <div className='col-sm-8'>
                                            <Field name="currentcompany" type="text" className="form-control" placeholder="Freshers can leave it blank" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Years of Experience</label>
                                        <div className='col-sm-8'>
                                            <Field name="yearsOfExperience" type="text" className="form-control" placeholder="e.g. - 1.3 Years" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Current Designation</label>
                                        <div className='col-sm-8'>
                                            <Field name="currentDesignation" type="text" className="form-control" placeholder="Current Designation" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Notice Period (In Months)</label>
                                        <div className='col-sm-8'>
                                            <Field name="noticePeriod" type="text" className="form-control" placeholder="Notice Period (In Months)" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Current Salary</label>
                                        <div className='col-sm-8'>
                                            <Field name="currentSalary" type="text" className="form-control" placeholder="In LPA" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Mention Your Skills</label>
                                        <div className='col-sm-8'>
                                            <Field name="skills" type="text" className="form-control" placeholder="Write name of your major skills" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Work Location Preferences</label>
                                        <div className='col-sm-8'>
                                            <Field name="workLocation" type="text" className="form-control" placeholder="Write name of preferred cities. Write Remote if looking for WFH" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <span id="my-radio-group" className="col-sm-4 col-form-label">Looking for Full Time / Part Time</span>
                                        <div className='col-sm-8'>
                                            <span role="group" aria-labelledby="my-radio-group">
                                                <label>
                                                    <Field type="checkbox" name="typePartFull" value="Full Time" className="form-check-input" />
                                                    Full Time
                                                </label>
                                                <label>
                                                    <Field type="checkbox" name="typePartFull" value="Part Time" className="form-check-input" />
                                                    Part Time
                                                </label>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <span id="my-radio-group" className="col-sm-4 col-form-label">Looking for Job / Internship</span>
                                        <div className='col-sm-8'>
                                            <span role="group" aria-labelledby="my-radio-group">
                                                <label>
                                                    <Field type="checkbox" name="typeFullIntern" value="Job" className="form-check-input" />
                                                    Job
                                                </label>
                                                <label>
                                                    <Field type="checkbox" name="typeFullIntern" value="Internship" className="form-check-input" />
                                                    Internship
                                                </label>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <span id="my-radio-group" className="col-sm-4 col-form-label">Job Domain</span>
                                        <div className='col-sm-8'>
                                            <span role="group" aria-labelledby="my-radio-group">
                                                <label>
                                                    <Field type="radio" name="jobType" value="Technology" className="form-check-input" />
                                                    Technology
                                                </label>
                                                <label>
                                                    <Field type="radio" name="jobType" value="Business Development" className="form-check-input" />
                                                    Business Development
                                                </label>
                                                <label>
                                                    <Field type="radio" name="jobType" value="HR" className="form-check-input" />
                                                    HR
                                                </label>
                                                <label>
                                                    <Field type="radio" name="jobType" value="Marketing" className="form-check-input" />
                                                    Marketing
                                                </label>
                                                <label>
                                                    <Field type="radio" name="jobType" value="Trainer/Teaching" className="form-check-input" />
                                                    Trainer/Teaching
                                                </label>
                                            </span>
                                        </div>
                                    </div>

                                    <br></br>
                                    <button type="submit" className='btn btn-success'>Register</button>

                                    <br></br>
                                    <br></br>
                                </Form>
                            </Formik>

                            {
                                apistatus && (
                                    <div className="alert alert-success" role="alert">
                                        You are successfully registered.
                                    </div>
                                )
                            }

                            {
                                error && (
                                    <div className="alert alert-danger" role="alert">
                                        Server Error. Please, try after sometime.
                                    </div>
                                )
                            }
                        </div>

                        {loader && (
                            <div className="loading">
                                <img src={loadImage} alt="Loader" />
                            </div>
                        )
                        }

                        <div className="col-12">
                            <h3 className="contact-title"><br></br>Or, Drop CV at hashedbit@gmail.com.</h3>
                        </div>

                    </div>
                </div>
            </section>
            
            // 
            
            
            
            
            
            )
            }
        </>
    )
}

export default Career
