import React from 'react';
import { Link } from 'react-router-dom';

const BlogCategories = (props) => {
    const { categories } = props;
    return (
        <>
            <aside class="single_sidebar_widget post_category_widget">
                <h4 class="widget_title">Blog Categories</h4>
                <ul class="list cat-list">
                    {
                        categories.map((item, index) => (
                            <li key={index}>
                                <Link to={`/blogs/category/${item.name}`} href="#" class="d-flex">
                                    <p>{item.displayname}</p>
                                    <p>&nbsp;({item.count})</p>
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </aside>
        </>
    )
}

export default BlogCategories
