import React from "react";
import { Link } from "react-router-dom";
import image1 from "../assets/img/home/1.jpg";
import image2 from "../assets/img/home/2.jpg";
import image3 from "../assets/img/home/3.jpg";
import prof from "../assets/img/home/prof.png";
import prof2 from "../assets/img/home/prof2.jpg";
import biz from "../assets/img/home/biz.png";

const Home = () => {
  return (
    <>
      <main>
        {/* <!-- Slider Area Start--> */}
        <div class="slider-area bg-image">
          <div class="slider-active">
            <div class="single-slider slider-height slider-padding sky-blue d-flex align-items-center">
              <div class="container">
                <div class="row d-flex align-items-center">
                  <div class="col-lg-12 col-md-12 text-center ">
                    <div class="hero__caption">
                      {/* <span data-animation="fadeInUp" data-delay=".4s">App Landing Page</span> */}
                      <h1
                        data-animation="fadeInUp"
                        data-delay=".6s"
                        className="text-light"
                      >
                        One Stop solution for all Web and Software Solutions
                      </h1>
                      {/* <p data-animation="fadeInUp" data-delay=".8s">Dorem ipsum dolor sitamet, consectetur adipiscing elit, sed do eiusm tempor incididunt ulabore et dolore magna aliqua.</p> */}
                      {/* <!-- Slider btn --> */}
                      {/* <div class="slider-btns">
                                                <a data-animation="fadeInLeft" data-delay="1.0s" href="industries.html" class="btn radius-btn">Download</a>
                                                <a data-animation="fadeInRight" data-delay="1.0s" class="popup-video video-btn ani-btn" href="https://www.youtube.com/watch?v=1aP-TXUpNoU"><i class="fas fa-play"></i></a>
                                            </div> */}
                    </div>
                  </div>
                  {/* <div class="col-lg-6">
                                        <div class="hero__img d-none d-lg-block f-right" data-animation="fadeInRight" data-delay="1s">
                                            <img src={hero_right} alt="" />
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <section class="service-area sky-blue bg-curve">
          <div class="container">
            <div class="row d-flex justify-content-center pt-200">
              <div class="col-lg-12">
                <div class="section-tittle text-center">
                  <h2>
                    <br></br>How Can We Help You with Our Services
                  </h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-caption text-center mb-30 home-main-div">
                  <div class="service-icon">
                    <img src={image3} alt="" className="img-fluid" />
                    {/* <span class="flaticon-businessman"></span> */}
                  </div>
                  <div class="service-cap">
                    <h4>
                      <a>
                        <br></br>Web Development
                      </a>
                    </h4>
                    <p>Website Designing</p>
                    <p>E-Commerce Development</p>
                    <p>Custom Portal Development</p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-caption text-center mb-30 home-main-div">
                  <div class="service-icon">
                    <img src={image2} alt="" className="img-fluid" />
                    {/* <span class="flaticon-pay"></span> */}
                  </div>
                  <div class="service-cap">
                    <h4>
                      <a>
                        <br></br>Mobile Applications
                      </a>
                    </h4>
                    <p>Android Mobile Applications</p>
                    <p>IOS Mobile Applications</p>
                    <p>Custom Mobile Applications</p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-caption text-center mb-30 home-main-div">
                  <div class="service-icon">
                    <img src={image1} alt="" className="img-fluid" />
                    {/* <span class="flaticon-plane"></span> */}
                  </div>
                  <div class="service-cap">
                    <h4>
                      <a>
                        <br></br>Digital marketing
                      </a>
                    </h4>
                    <p>SEO Services</p>
                    <p>Social Media Marketing</p>
                    <p>Brand Building</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section class="best-features-area">
                    <div class="container">
                        <div class="row justify-content-end">
                            <div class="col-xl-12 col-lg-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="section-tittle text-center">
                                            <h2>We believe all businesses should have online presence and access to digital solutions for growth.</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-">
                                        <div class="single-features mb-70">
                                            <div class="features-caption">
                                                <h3>Customer Support</h3>
                                                <p>Aorem psum olorsit amet ectetur adipiscing elit, sed dov.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6">
                                        <div class="single-features mb-70">
                                            <div class="features-caption">
                                                <h3>User Experience</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6">
                                        <div class="single-features mb-70">
                                            <div class="features-caption">
                                                <h3>Powerful Backend</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6">
                                        <div class="single-features mb-70">
                                            <div class="features-caption">
                                                <h3>Branding Design</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6">
                                        <div class="single-features mb-70">
                                            <div class="features-caption">
                                                <h3>Latest Technologies</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6">
                                        <div class="single-features mb-70">
                                            <div class="features-caption">
                                                <h3>End to End Solutions</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="features-shpae d-none d-lg-block">
                        <img src="assets/img/shape/best-features.png" alt="" />
                    </div>
                </section> */}

        <section class="service-area sky-blue bg-home2">
          <div class="container">
            {/* <div class="row d-flex justify-content-center pt-200">
                            <div class="col-lg-12">
                                <div class="section-tittle text-center">
                                    <h2><br></br>How Can We Help You with Our Services</h2>
                                </div>
                            </div>
                        </div> */}
            <div class="row">
              <div class="col-md-6">
                <div class="text-start">
                  <img src={biz} alt="" className="img-fluid" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-30">
                  <div class="service-cap">
                    <h4 className="mb-60 hb-semibold">
                      We believe all businesses should have online presence and
                      access to digital solutions for growth.
                    </h4>
                    <p className="mb-10">Customer Support</p>
                    <p className="mb-10">User Experience</p>
                    <p className="mb-10">Powerful Backend</p>
                    <p className="mb-10">Branding Design</p>
                    <p className="mb-10">Latest Technologies</p>
                    <p className="mb-10">End to End Solutions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="service-area sky-blue bg-home">
          <div class="container">
            {/* <div class="row d-flex justify-content-center pt-200">
                            <div class="col-lg-12">
                                <div class="section-tittle text-center">
                                    <h2><br></br>How Can We Help You with Our Services</h2>
                                </div>
                            </div>
                        </div> */}
            <div class="row">
              <div class="col-md-6">
                <div class="mb-30">
                  <div class="service-cap">
                    <h4 className="mb-60 hb-bold">
                      The Best IT Service Provider
                    </h4>
                    <p className="mb-60">
                      Managed IT services can help you outsource your IT needs
                      to a third-party provider. This includes IT support,
                      network monitoring, and maintenance, and disaster recovery
                      and business continuity planning.
                    </p>
                    <Link to="/contact" className="button-blue">
                      Connect With Us
                    </Link>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="text-end">
                  <img src={prof} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>
    </>
  );
};

export default Home;
