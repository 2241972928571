import React from 'react';
import { Link } from 'react-router-dom';
import tickimage from '../../assets/img/learning/tick.svg';

import image1 from '../../assets/img/workshop/iitk.jpg';
import image2 from '../../assets/img/workshop/ieeenpl2.jpg';
import image3 from '../../assets/img/workshop/nitb.jpg';
import image4 from '../../assets/img/workshop/rits.jpg';
import image5 from '../../assets/img/workshop/sagar1.jpg';
import image6 from '../../assets/img/workshop/sagar2.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const CorporateTraining = () => {
    return (
        <>
            <section class="services-area service-area services-padding">
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-12">
                            <div class="section-tittle text-center">
                                <h2>Corporate Training Programs</h2>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 bg-warning bg-opacity-10 py-4 text-center">
                            <h5>Programming Languages / DevOps / Big Data / AI ML / Full Stack / Software Testing</h5>
                        </div>
                    </div>


                    <div className='row'>

                        <div className='col-12'>

                            <div class="container-1280px round-container">
                                <div class="round-blocks-contain">
                                    <div class="w-layout-grid round-column-grid">
                                        <div class="round-block-wapper _1">
                                            <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa495_laptop-code.svg" loading="lazy" alt="" class="round-block-icon extra" />
                                            <div class="paragraph-22px round-subheading-text">Campus Training Programs</div>
                                            <div class="paragraph-22px round-point-text">Company Office</div>
                                        </div><div class="round-block-wapper _2">
                                            <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa497_lightbulb-on.svg" loading="lazy" alt="" class="round-block-icon" />
                                            <div class="paragraph-22px round-subheading-text">Virtual Trainings</div>
                                            <div class="paragraph-22px round-point-text">Instructor led sessions</div>
                                        </div></div><div class="w-layout-grid round-column-grid">
                                        <div class="round-block-wapper _3">
                                            <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa494_atom-simple%201.svg" loading="lazy" alt="" class="round-block-icon" />
                                            <div class="paragraph-22px round-subheading-text">Technology Seminars</div>
                                            <div class="paragraph-22px round-point-text">Online / Offline</div>
                                        </div>
                                        <div class="round-block-wapper _4">
                                            <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa496_gears.svg" loading="lazy" alt="" class="round-block-icon extra" />
                                            <div class="paragraph-22px round-subheading-text">Technical Conferences</div>
                                            <div class="paragraph-22px round-point-text">Technology Expert Lectures</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="round-text-wrapper">

                                    <div class="round-check-list-container">
                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Online/Offline Training Programs</div></div>
                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Customised Programs as per requirement</div>
                                        </div>

                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Flexible durations</div>
                                        </div>
                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Sessions by Industry Experts</div>
                                        </div>
                                        

                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Hands On Sessions</div>
                                        </div>

                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Guest Lectures by Technology Experts</div>
                                        </div>

                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Latest Technology based content</div>
                                        </div>

                                    </div>

                                    <Link><div class="btn btn-primary">
                                        <div class="paragraph-18px colored-button-text">Interested ! Call at 95611-33644 for more details </div>

                                    </div>
                                    </Link>

                                </div></div>
                        </div>
                    </div>



                    <div className="row my-5">
                        <div className="companies pb-5">
                            <div className="companies-title">
                                <h2>Our Corporate Training Program Glimpses</h2>
                                {/* <p>HashedBit Innovations work at some of the leading and innovative <br /> organizations of today, solving core business problems.</p> */}
                            </div>
                        </div>
                        <div className="companies-images">
                            <Swiper
                                slidesPerView={3}
                                pagination={{
                                    type: 'fraction',
                                }}
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide><img src={image1} alt="Logo 1" /></SwiperSlide>
                                <SwiperSlide><img src={image2} alt="Logo 2" /></SwiperSlide>
                                {/* <SwiperSlide><img src={image3} alt="Logo 3" /></SwiperSlide> */}
                                {/* <SwiperSlide><img src={image4} alt="Logo 4" /></SwiperSlide> */}
                                {/* <SwiperSlide><img src={image5} alt="Logo 5" /></SwiperSlide> */}
                                <SwiperSlide><img src={image6} alt="Logo 6" /></SwiperSlide>
                            </Swiper>
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default CorporateTraining;
