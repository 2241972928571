import React from "react";
import mernimage from "../assets/img/learning/mern.svg";
import mainimage from "../assets/img/learning/main-pic.png";
import logo1 from "../assets/img/learning/company-logo1.jpg";
import logo2 from "../assets/img/learning/comapny-logo2.jpg";
import logo3 from "../assets/img/learning/company-logo3.jpg";
import logo4 from "../assets/img/learning/company-logo4.png";
import logo5 from "../assets/img/learning/company-logo5.jpg";
import logo6 from "../assets/img/learning/comapny-logo6.jpg";

import ibm from '../assets/img/learning/ibm.png';
import visa from '../assets/img/learning/visa.png';
import walmart from '../assets/img/learning/walmart.png';
import paypal from '../assets/img/learning/paypal.png';
import tcs from '../assets/img/learning/tcs.png';
import wipro from '../assets/img/learning/wipro.png';
import infosys from '../assets/img/learning/infosys.png';
import capgemini from '../assets/img/learning/capgemini.png';
import swiggy from '../assets/img/learning/swiggy.png';
import shiprocket from '../assets/img/learning/shiprocket.png';
import upstox from '../assets/img/learning/upstox.png';
import amdocs from '../assets/img/learning/amdocs.png';

import { Link } from "react-router-dom";
import { FaArrowRight, FaPhoneVolume, FaStar } from "react-icons/fa6";
import { TfiBlackboard } from "react-icons/tfi";
import { FaComputer, FaUsers, FaList, FaNetworkWired, FaNodeJs, FaBlackTie, FaBuffer, FaUserGraduate, } from "react-icons/fa6";

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Learning = () => {

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };

  return (
    <>
      <section class="services-area service-area services-padding">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-12">
              <div class="section-tittle text-center">
                <h2>Learning Oppotunities @ HashedBit</h2>
              </div>
            </div>
          </div>
          {/* <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <Link to='/learning/mern'>
                                <div class="services-caption active text-center mb-30">
                                    <div class="service-icon">
                                        <span class="flaticon-businessman">
                                            <img src={mernimage} alt="mern" className='learning-icons' />
                                        </span>
                                    </div>
                                    <div class="service-cap">
                                        <h4><a>MERN Full Stack</a></h4>
                                        <p>Starting from 30 October</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <Link to='/learning/placement'>
                                <div class="services-caption active text-center mb-30">
                                    <div class="service-icon">
                                        <span class="flaticon-pay">
                                            <img src={mernimage} alt="mern" className='learning-icons' />
                                        </span>
                                    </div>
                                    <div class="service-cap">
                                        <h4><a>Placement Batch</a></h4>
                                        <p>Starting from 30 October</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <Link to='/learning/internship'>
                                <div class="services-caption active text-center mb-30">
                                    <div class="service-icon">
                                        <span class="flaticon-plane">
                                            <img src={mernimage} alt="mern" className='learning-icons' />
                                        </span>
                                    </div>
                                    <div class="service-cap">
                                        <h4><a>Free Internship</a></h4>
                                        <p>Register Soon</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                    </div> */}
          {/* <div className="row">

            <div className="trust-us">
              <div className="trust-us-title">
                <h2>Do not trust us, trust them</h2>

                <p>
                  HashedBit Innovations work at some of the leading and innovative <br />{" "}
                  organizations of today, solving core business problems.
                </p>
              </div>
            </div>

            <div className="trust-us-cards">

              <Swiper
                slidesPerView={3}
                spaceBetween={10}
                pagination={{
                  type: "fraction",
                }}
                navigation={true}
                modules={[Navigation]}

                className="my-trust-swiper"

                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  400: {
                    slidesPerView: 1,
                  },
                  700: {
                    slidesPerView: 2
                  },
                  1000: {
                    slidesPerView: 3
                  },
                  1700: {
                    slidesPerView: 4
                  }
                }}
              >
                <SwiperSlide>
                  <div className="card-style">

                    <div className="person">
                      <div className="card-image-style">
                        <img
                          src="https://barrownzlearning.com/Content/img/testimonial/SURAJ%20TIWARI.webp"
                          className="image-style"
                          alt="Card Img"
                        />
                      </div>
                      <div className="person-details">
                        <div className="name">Suraj Tiwari</div>
                        <div className="role">Student</div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="trust-us-card-title">Good Placement Coordinator</h5>
                      <p className="card-text trust-us-card-text">

                        We believe that everyone has the right to education and with this
                        believe we do not allow finances to hinder the growth of students
                        and hence we have made our courses affordable and accessible to
                        all.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-style">

                    <div className="person">
                      <div className="card-image-style">
                        <img
                          src="https://barrownzlearning.com/Content/img/testimonial/SURAJ%20TIWARI.webp"
                          className="image-style"
                          alt="Card Img"
                        />
                      </div>
                      <div className="person-details">
                        <div className="name">Suraj Tiwari</div>
                        <div className="role">Student</div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="trust-us-card-title">Good Placement Coordinator</h5>
                      <p className="card-text trust-us-card-text">

                        We believe that everyone has the right to education and with this
                        believe we do not allow finances to hinder the growth of students
                        and hence we have made our courses affordable and accessible to
                        all.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-style">

                    <div className="person">
                      <div className="card-image-style">
                        <img
                          src="https://barrownzlearning.com/Content/img/testimonial/SURAJ%20TIWARI.webp"
                          className="image-style"
                          alt="Card Img"
                        />
                      </div>
                      <div className="person-details">
                        <div className="name">Suraj Tiwari</div>
                        <div className="role">Student</div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="trust-us-card-title">Good Placement Coordinator</h5>
                      <p className="card-text trust-us-card-text">

                        We believe that everyone has the right to education and with this
                        believe we do not allow finances to hinder the growth of students
                        and hence we have made our courses affordable and accessible to
                        all.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-style">

                    <div className="person">
                      <div className="card-image-style">
                        <img
                          src="https://barrownzlearning.com/Content/img/testimonial/SURAJ%20TIWARI.webp"
                          className="image-style"
                          alt="Card Img"
                        />
                      </div>
                      <div className="person-details">
                        <div className="name">Suraj Tiwari</div>
                        <div className="role">Student</div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="trust-us-card-title">Good Placement Coordinator</h5>
                      <p className="card-text trust-us-card-text">

                        We believe that everyone has the right to education and with this
                        believe we do not allow finances to hinder the growth of students
                        and hence we have made our courses affordable and accessible to
                        all.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-style">

                    <div className="person">
                      <div className="card-image-style">
                        <img
                          src="https://barrownzlearning.com/Content/img/testimonial/SURAJ%20TIWARI.webp"
                          className="image-style"
                          alt="Card Img"
                        />
                      </div>
                      <div className="person-details">
                        <div className="name">Suraj Tiwari</div>
                        <div className="role">Student</div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="trust-us-card-title">Good Placement Coordinator</h5>
                      <p className="card-text trust-us-card-text">

                        We believe that everyone has the right to education and with this
                        believe we do not allow finances to hinder the growth of students
                        and hence we have made our courses affordable and accessible to
                        all.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="our-courses">
              <div className="our-courses-head">
                <h2>Courses we offer</h2>
                <p>
                  HashedBit Innovations work at some of the leading and innovative <br />{" "}
                  organizations of today, solving core business problems.
                </p>
              </div>
            </div>
            <div className="our-courses-cards">
              <Swiper
                slidesPerView={3}
                spaceBetween={10}
                pagination={{
                  type: "fraction",
                }}
                navigation={true}
                modules={[Navigation]}
                className="my-learning-swiper"
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  400: {
                    slidesPerView: 1,
                  },
                  700: {
                    slidesPerView: 2,
                  },
                  1000: {
                    slidesPerView: 3,
                  },
                  1700: {
                    slidesPerView: 4,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="card-style">
                    <a href="/learning/mern" className="card-image-style">
                      <img
                        src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                        className="image-style"
                        alt="Card Img"
                      />
                    </a>
                    <div className="card-body">
                      <div className="row our-courses-detail">
                        <div className="col our-courses-lang">Hindi/English</div>
                        <div className="col our-courses-rating">
                          <FaStar />
                          4.1(40)
                        </div>
                      </div>
                      <h5 className="card-title our-courses-title">Summer Training</h5>
                    </div>
                    <div className="card-body">
                      <div className="row our-courses-links">
                        <a
                          href="/learning/registration/mern"
                          className="col card-link our-courses-enroll"
                        >
                          <TfiBlackboard />
                          Enroll Now
                        </a>
                        <a
                          href="/learning/mern"
                          className="col card-link our-courses-details"
                        >
                          View Details
                          <FaArrowRight />
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-style">
                    <a href="/learning/mern" className="card-image-style">
                      <img
                        src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                        className="image-style"
                        alt="Card Img"
                      />
                    </a>
                    <div className="card-body">
                      <div className="row our-courses-detail">
                        <div className="col our-courses-lang">Hindi/English</div>
                        <div className="col our-courses-rating">
                          <FaStar />
                          4.1(40)
                        </div>
                      </div>
                      <h5 className="card-title our-courses-title">Summer Training</h5>
                    </div>
                    <div className="card-body">
                      <div className="row our-courses-links">
                        <a
                          href="/learning/registration/mern"
                          className="col card-link our-courses-enroll"
                        >
                          <TfiBlackboard />
                          Enroll Now
                        </a>
                        <a
                          href="/learning/mern"
                          className="col card-link our-courses-details"
                        >
                          View Details
                          <FaArrowRight />
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-style">
                    <a href="/learning/mern" className="card-image-style">
                      <img
                        src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                        className="image-style"
                        alt="Card Img"
                      />
                    </a>
                    <div className="card-body">
                      <div className="row our-courses-detail">
                        <div className="col our-courses-lang">Hindi/English</div>
                        <div className="col our-courses-rating">
                          <FaStar />
                          4.1(40)
                        </div>
                      </div>
                      <h5 className="card-title our-courses-title">Summer Training</h5>
                    </div>
                    <div className="card-body">
                      <div className="row our-courses-links">
                        <a
                          href="/learning/registration/mern"
                          className="col card-link our-courses-enroll"
                        >
                          <TfiBlackboard />
                          Enroll Now
                        </a>
                        <a
                          href="/learning/mern"
                          className="col card-link our-courses-details"
                        >
                          View Details
                          <FaArrowRight />
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-style">
                    <a href="/learning/mern" className="card-image-style">
                      <img
                        src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                        className="image-style"
                        alt="Card Img"
                      />
                    </a>
                    <div className="card-body">
                      <div className="row our-courses-detail">
                        <div className="col our-courses-lang">Hindi/English</div>
                        <div className="col our-courses-rating">
                          <FaStar />
                          4.1(40)
                        </div>
                      </div>
                      <h5 className="card-title our-courses-title">Summer Training</h5>
                    </div>
                    <div className="card-body">
                      <div className="row our-courses-links">
                        <a
                          href="/learning/registration/mern"
                          className="col card-link our-courses-enroll"
                        >
                          <TfiBlackboard />
                          Enroll Now
                        </a>
                        <a
                          href="/learning/mern"
                          className="col card-link our-courses-details"
                        >
                          View Details
                          <FaArrowRight />
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-style">
                    <a href="/learning/mern" className="card-image-style">
                      <img
                        src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                        className="image-style"
                        alt="Card Img"
                      />
                    </a>
                    <div className="card-body">
                      <div className="row our-courses-detail">
                        <div className="col our-courses-lang">Hindi/English</div>
                        <div className="col our-courses-rating">
                          <FaStar />
                          4.1(40)
                        </div>
                      </div>
                      <h5 className="card-title our-courses-title">Summer Training</h5>
                    </div>
                    <div className="card-body">
                      <div className="row our-courses-links">
                        <a
                          href="/learning/registration/mern"
                          className="col card-link our-courses-enroll"
                        >
                          <TfiBlackboard />
                          Enroll Now
                        </a>
                        <a
                          href="/learning/mern"
                          className="col card-link our-courses-details"
                        >
                          View Details
                          <FaArrowRight />
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-style">
                    <a href="/learning/mern" className="card-image-style">
                      <img
                        src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                        className="image-style"
                        alt="Card Img"
                      />
                    </a>
                    <div className="card-body">
                      <div className="row our-courses-detail">
                        <div className="col our-courses-lang">Hindi/English</div>
                        <div className="col our-courses-rating">
                          <FaStar />
                          4.1(40)
                        </div>
                      </div>
                      <h5 className="card-title our-courses-title">Summer Training</h5>
                    </div>
                    <div className="card-body">
                      <div className="row our-courses-links">
                        <a
                          href="/learning/registration/mern"
                          className="col card-link our-courses-enroll"
                        >
                          <TfiBlackboard />
                          Enroll Now
                        </a>
                        <a
                          href="/learning/mern"
                          className="col card-link our-courses-details"
                        >
                          View Details
                          <FaArrowRight />
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div> */}

          <div className="row">
            <div class="boxes-area">
              <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                  <div class="single-box">
                    <div class="icon">
                      <span class="flaticon-plane">
                        <FaList
                          style={{
                            fontSize: "2em",
                            widows: "65px",
                            height: "35px",
                          }}
                        />
                      </span>
                    </div>
                    <h3>
                      <Link>Simplified Internships</Link>
                    </h3>
                    <p>
                      Explore virtual internships in Web Dev, App Dev, Full
                      Stack, ML, and Data Science; focused learning
                    </p>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <div class="single-box bg-f78acb">
                    <div class="icon">
                      <span class="flaticon-plane">
                        <FaNetworkWired
                          style={{
                            fontSize: "2em",
                            widows: "65px",
                            height: "35px",
                          }}
                        />
                      </span>
                    </div>
                    <h3>
                      <Link>Build your Network</Link>
                    </h3>
                    <p>
                      We believe in networking to connect with other interns who
                      are interested in the same things.
                    </p>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <div class="single-box bg-c679e3">
                    <div class="icon">
                      <span class="flaticon-plane3">
                        <FaUsers
                          style={{
                            fontSize: "2em",
                            widows: "65px",
                            height: "35px",
                          }}
                        />
                      </span>
                    </div>
                    <h3>
                      <Link>Building Student Trust</Link>
                    </h3>
                    <p>
                      We ensure students success and build a trusted platform
                      for valuable learning experiences for them.
                    </p>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <div class="single-box">
                    <div class="icon">
                      <span class="flaticon-plane3">
                        <FaComputer
                          style={{
                            fontSize: "2em",
                            widows: "65px",
                            height: "35px",
                          }}
                        />
                      </span>
                    </div>
                    <h3>
                      <Link>Structured Learning</Link>
                    </h3>
                    <p>
                      Enjoy growth-oriented internship at Bharat Intern,
                      tailored for maximum skill development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-6">
              <Link to="/learning/mern">
                <div class="services-caption active text-center mb-30 single-box">
                  <div class="service-icon">
                    <span class="flaticon-businessman">
                      <FaNodeJs
                        style={{
                          fontSize: "2em",
                          widows: "65px",
                          height: "35px",
                        }}
                      />
                      {/* <img src={mernimage} alt="mern" className='learning-icons' /> */}
                    </span>
                  </div>
                  <div class="service-cap">
                    <h4>
                      <a>MERN Full Stack</a>
                    </h4>
                    <p>Starting from 22nd April</p>
                  </div>
                </div>
              </Link>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-6">
              <Link to="/learning/placement">
                <div class="services-caption active text-center mb-30 single-box">
                  <div class="service-icon">
                    <span class="flaticon-pay">
                      <FaBlackTie
                        style={{
                          fontSize: "2em",
                          widows: "65px",
                          height: "35px",
                        }}
                      />
                      {/* <img src={mernimage} alt="mern" className='learning-icons' /> */}
                    </span>
                  </div>
                  <div class="service-cap">
                    <h4>
                      <a>Placement Batch</a>
                    </h4>
                    <p>Starting from 22nd April</p>
                  </div>
                </div>
              </Link>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-6">
              <Link to="/learning/internship">
                <div class="services-caption active text-center mb-30 single-box">
                  <div class="service-icon">
                    <span class="flaticon-plane">
                      <FaBuffer
                        style={{
                          fontSize: "2em",
                          widows: "65px",
                          height: "35px",
                        }}
                      />
                      {/* <img src={mernimage} alt="mern" className='learning-icons' /> */}
                    </span>
                  </div>
                  <div class="service-cap">
                    <h4>
                      <a>Tech Internship</a>
                    </h4>
                    <p>Registration Started</p>
                  </div>
                </div>
              </Link>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-6">
              <Link to="/learning/mbainternship">
                <div class="services-caption active text-center mb-30 single-box">
                  <div class="service-icon">
                    <span class="flaticon-plane">
                      <FaUserGraduate
                        style={{
                          fontSize: "2em",
                          widows: "65px",
                          height: "35px",
                        }}
                      />
                      {/* <img src={mernimage} alt="mern" className='learning-icons' /> */}
                    </span>
                  </div>
                  <div class="service-cap">
                    <h4>
                      <a>MBA Internship</a>
                    </h4>
                    <p>Registration Started</p>
                  </div>
                </div>
              </Link>
            </div>


            <div class="col-xl-3 col-lg-3 col-md-6">
              <Link to="/learning/mockinterview">
                <div class="services-caption active text-center mb-30 single-box">
                  <div class="service-icon">
                    <span class="flaticon-plane">
                      <FaUserGraduate
                        style={{
                          fontSize: "2em",
                          widows: "65px",
                          height: "35px",
                        }}
                      />
                      {/* <img src={mernimage} alt="mern" className='learning-icons' /> */}
                    </span>
                  </div>
                  <div class="service-cap">
                    <h4>
                      <a>Mock Interview &nbsp;</a>
                    </h4>
                    <p>Registration Started</p>
                  </div>
                </div>
              </Link>
            </div>


            <div class="col-xl-3 col-lg-3 col-md-6">
              <Link to="/learning/collegeinternship">
                <div class="services-caption active text-center mb-30 single-box">
                  <div class="service-icon">
                    <span class="flaticon-plane">
                      <FaUserGraduate
                        style={{
                          fontSize: "2em",
                          widows: "65px",
                          height: "35px",
                        }}
                      />
                      {/* <img src={mernimage} alt="mern" className='learning-icons' /> */}
                    </span>
                  </div>
                  <div class="service-cap">
                    <h4>
                      <a>College Internship</a>
                    </h4>
                    <p>Registration Started</p>
                  </div>
                </div>
              </Link>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-6">
              <Link to="/learning/collegeworkshop">
                <div class="services-caption active text-center mb-30 single-box">
                  <div class="service-icon">
                    <span class="flaticon-plane">
                      <FaUserGraduate
                        style={{
                          fontSize: "2em",
                          widows: "65px",
                          height: "35px",
                        }}
                      />
                      {/* <img src={mernimage} alt="mern" className='learning-icons' /> */}
                    </span>
                  </div>
                  <div class="service-cap">
                    <h4>
                      <a>&nbsp;&nbsp;&nbsp;Workshops&nbsp;&nbsp;&nbsp;</a>
                    </h4>
                    <p>Know More</p>
                  </div>
                </div>
              </Link>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-6">
              <Link to="/learning/corporatetraining">
                <div class="services-caption active text-center mb-30 single-box">
                  <div class="service-icon">
                    <span class="flaticon-plane">
                      <FaUserGraduate
                        style={{
                          fontSize: "2em",
                          widows: "65px",
                          height: "35px",
                        }}
                      />
                      {/* <img src={mernimage} alt="mern" className='learning-icons' /> */}
                    </span>
                  </div>
                  <div class="service-cap">
                    <h4>
                      <a>Corporate Training</a>
                    </h4>
                    <p>Know More</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* <div class="our-customer pt-50 pb-100">
                <div class="container-fluid">
                    <div class="our-customer-wrapper">
                        <div class="row d-flex justify-content-center">
                            <div class="col-xl-8">
                                <div class="section-tittle text-center">
                                    <h2>Our Technologies</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            
                        </div>
                    </div>
                </div>
            </div >

            <div class="our-customer pt-50 pb-100">
                <div class="container-fluid">
                    <div class="our-customer-wrapper">
                        <div class="row d-flex justify-content-center">
                            <div class="col-xl-8">
                                <div class="section-tittle text-center">
                                    <h2>Our Clients</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            
                        </div>
                    </div>
                </div>
            </div> */}


        <div className="row bg-secondary text-white bluegreenbg mb-5">
          <div className="col-12 contact text-center py-5">
            <div className="contact-content">
              <h1>Be a part of our Campus Ambassador Program</h1>
              {/* <p>Get a free counselling session from our experts</p> */}
              <Link to='/learning/campusambassador' className="btn btn-primary">Know More</Link>
            </div>
          </div>
        </div>


        <div className="row">
          <div class="services-area services-bg">
            <div class="container pb-5">
              <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 services-left-image">
                  <img
                    src={mainimage}
                    class="animate__animated animate__fadeInUp animate__delay-0.2s"
                    alt="main-pic"
                  />
                </div>
                <div class="col-lg-6 col-md-12 services-content">
                  <div class="">
                    <h2>Get Specialised in these Domains</h2>
                    <p>
                      At HashedBit Innovations, we excel in various domains to
                      bring your ideas to life. Our skills include:
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-lg-9 col-md-6">
                      <div class="box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polyline points="16 18 22 12 16 6"></polyline>
                          <polyline points="8 6 2 12 8 18"></polyline>
                        </svg>
                        <Link>Web Development Basic</Link>
                      </div>
                    </div>
                    <div class="col-lg-9 col-md-6">
                      <div class="box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <rect
                            x="5"
                            y="2"
                            width="14"
                            height="20"
                            rx="2"
                            ry="2"
                          ></rect>
                          <line x1="12" y1="18" x2="12.01" y2="18"></line>
                        </svg>
                        <Link>Android Apps Development</Link>
                      </div>
                    </div>
                    <div class="col-lg-9 col-md-6">
                      <div class="box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polyline points="16 18 22 12 16 6"></polyline>
                          <polyline points="8 6 2 12 8 18"></polyline>
                        </svg>
                        <Link>Full Stack Web Development</Link>
                      </div>
                    </div>

                    <div class="col-lg-9 col-md-6">
                      <div class="box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polyline points="16 18 22 12 16 6"></polyline>
                          <polyline points="8 6 2 12 8 18"></polyline>
                        </svg>
                        <Link>Machine Learning</Link>
                      </div>
                    </div>
                    <div class="col-lg-9 col-md-6">
                      <div class="box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polyline points="16 18 22 12 16 6"></polyline>
                          <polyline points="8 6 2 12 8 18"></polyline>
                        </svg>
                        <Link>Data Science</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row bg-secondary text-white bluegreenbg mb-5">
          <div className="col-12 contact text-center py-5">
            <div className="contact-content">
              <h1>Talk to our Learning Consultant!</h1>
              {/* <p>Get a free counselling session from our experts</p> */}
              <h3><span className="icon">
                <FaPhoneVolume />
              </span>
                +91 96697-87936
              </h3>
            </div>
          </div>
          {/* <div className="col-6 contact ">
            <div className="contact-infom ">
              <span className="icon">
                <FaPhoneVolume />
              </span>
              <h5>Contact Number</h5>
            </div>
            <h2>+91 96697-87936</h2>
          </div> */}
        </div>
        <div className="row">
          <div className="companies">
            <div className="companies-title mb-5">
              <h2>Our Learner Work At</h2>
              <p>HashedBit Innovations alumni work at some of the leading and innovative organizations of today, solving core business problems.</p>
            </div>
          </div>
          <div className="companies-images">
            <Swiper
              slidesPerView={4}
              pagination={{
                type: 'fraction',
              }}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              {/* <SwiperSlide><img src={logo1} alt="Logo 1" /></SwiperSlide>
              <SwiperSlide><img src={logo2} alt="Logo 2" /></SwiperSlide>
              <SwiperSlide><img src={logo3} alt="Logo 3" /></SwiperSlide>
              <SwiperSlide><img src={logo4} alt="Logo 4" /></SwiperSlide>
              <SwiperSlide><img src={logo5} alt="Logo 5" /></SwiperSlide>
              <SwiperSlide><img src={logo6} alt="Logo 6" /></SwiperSlide>
              <SwiperSlide><img src={logo4} alt="Logo 6" /></SwiperSlide>
              <SwiperSlide><img src={logo2} alt="Logo 7" /></SwiperSlide>
              <SwiperSlide><img src={logo1} alt="Logo 8" /></SwiperSlide> */}

              <SwiperSlide><img src={ibm} className="companies-slider-image" alt="ibm" /></SwiperSlide>
              <SwiperSlide><img src={visa} className="companies-slider-image" alt="visa" /></SwiperSlide>
              <SwiperSlide><img src={walmart} className="companies-slider-image" alt="walmart" /></SwiperSlide>
              <SwiperSlide><img src={paypal} className="companies-slider-image" alt="paypal" /></SwiperSlide>
              <SwiperSlide><img src={tcs} className="companies-slider-image" alt="tcs" /></SwiperSlide>
              <SwiperSlide><img src={wipro} className="companies-slider-image" alt="wipro" /></SwiperSlide>
              <SwiperSlide><img src={infosys} className="companies-slider-image" alt="infosys" /></SwiperSlide>
              <SwiperSlide><img src={capgemini} className="companies-slider-image" alt="capgemini" /></SwiperSlide>
              <SwiperSlide><img src={swiggy} className="companies-slider-image" alt="swiggy" /></SwiperSlide>
              <SwiperSlide><img src={shiprocket} className="companies-slider-image" alt="shiprocket" /></SwiperSlide>
              <SwiperSlide><img src={upstox} className="companies-slider-image" alt="upstox" /></SwiperSlide>
              <SwiperSlide><img src={amdocs} className="companies-slider-image" alt="amdocs" /></SwiperSlide>
            </Swiper>
          </div>



          {/* <div className="slider-container">
            <Slider {...settings}>
              <div>
                <img src={logo1} alt="Logo 1" className="companies-slider-image" />
              </div>
              <div>
                <img src={logo2} alt="Logo 2" className="companies-slider-image" />
              </div>
              <div>
                <img src={logo3} alt="Logo 3" className="companies-slider-image" />
              </div>
              <div>
                <img src={logo4} alt="Logo 4" className="companies-slider-image" />
              </div>
              <div>
                <img src={logo5} alt="Logo 5" className="companies-slider-image" />
              </div>
              <div>
                <img src={logo6} alt="Logo 6" className="companies-slider-image" />
              </div>
            </Slider>
          </div> */}

        </div>



        {/* <-------- Frequently Asked Questions Start--------> */}
        {/* <div class="accordion accordion-flush " id="accordionFlushExample">
          <div class="accordion-item  ">
            <h1 class="text-center p-3 ">Frequently Asked Questions</h1>
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Who can enroll in courses at HashedBit Innovations ?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
              Our courses are open to individuals of all backgrounds, from beginners to experienced professionals. Whether you're a student looking to start your IT journey or a seasoned IT professional seeking to upgrade your skills, we have courses suitable for you.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                How do I enroll in a course at HashedBit Innovations ?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
              To enroll in a course, simply visit our website and browse through our course offerings. You can find detailed information about each course, including schedules, fees, and registration instructions. Follow the steps outlined on the website to complete your enrollment.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Can I make installment payments for fees ?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
              Yes, We understands the financial constraints that students may face. We offer flexible payment options, including installment plans, to help you manage your course fees more conveniently. Our admissions team can provide you with details on available installment plans and guide you through the payment process. Please contact our admissions office for further assistance and to discuss the payment options that best suit your needs.
              </div>
            </div>
          </div>
        </div> */}

        {/* <-------- Frequently Asked Questions End--------> */}
      </section>
    </>
  );
};

export default Learning;
