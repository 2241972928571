import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaFacebookSquare, FaTwitterSquare, FaInstagramSquare, FaLocationArrow } from "react-icons/fa";

const Footer = () => {
    return (
        // <div className='row secureheader'>
        //     <div className='col-12 bg-info bg-opacity-50 text-center'>
        //       <h3>HashedBit Innovations</h3>
        //     </div>
        //   </div>
        <>
            <footer>

                {/* <!-- Footer Start--> */}
                <div class="footer-main">
                    <div class="footer-area pt-50">
                        <div class="container">
                            <div class="row  justify-content-between">
                                <div class="col-lg-3 col-md-4 col-sm-8">
                                    <div class="single-footer-caption mb-30">
                                        {/* <!-- logo --> */}
                                        {/* <div class="footer-logo">
                          <a href="index.html">
                            <img src="assets/img/logo/logo2_footer.png" alt=""/></a>
                      </div> */}
                                        <div class="footer-tittle">
                                            <h4>HashedBit Innovations Pvt Ltd</h4>
                                            <div class="footer-pera">
                                                <p class="info1">HashedBit Innovations works with innovative ideas and having a crazy attitude towards our work; provides inexpensive yet effective array of total web and software solutions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-5">
                                    <div class="single-footer-caption mb-50">
                                        <div class="footer-tittle">
                                            <h4>Quick Links</h4>
                                            <ul>
                                                {/* <li><Link to="/">Home</Link></li> */}
                                                <li><Link to="/about">About</Link></li>
                                                <li><Link to="/services">Services</Link></li>
                                                <li><Link to="/products">Products</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>
                                                <li><Link to="/career">Career</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-7">
                                    <div class="single-footer-caption mb-50">
                                        <div class="footer-tittle">
                                            <h4>Courses</h4>
                                            <ul>
                                                <li><a>MERN Development</a></li>
                                                <li><a>LAMP Development</a></li>
                                                <li><a>Python Development</a></li>
                                                <li><a>Placement Suport</a></li>
                                                <li><a>Mobile Application</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-7">
                                    <div class="single-footer-caption mb-50">
                                        <div class="footer-tittle">
                                            <h4>Services</h4>
                                            <ul>
                                                <li><a>Web Development</a></li>
                                                <li><a>Digital Marketing</a></li>
                                                <li><a>Mobile App Development</a></li>
                                                <li><a>E-Commerce Development</a></li>
                                                <li><a>ERP Development</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-lg-3 col-md-6 col-sm-8">
                                    <div class="single-footer-caption mb-50">
                                        <div class="footer-tittle">
                                            <h4>Latest Articles</h4>
                                            <div class="footer-pera footer-pera2">
                                                <p>Coming Soon.</p>
                                            </div>
                                            
                                            <div class="footer-form">
                                                <h4>Subscribe for Newsletter</h4>
                                                <div id="mc_embed_signup">
                                                    <form target="_blank" action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01" method="get" class="subscribe_form relative mail_part" novalidate="true">
                                                        <input type="email" name="EMAIL" id="newsletter-form-email" placeholder=" Email Address " class="placeholder hide-on-focus" onfocus="this.placeholder = ''" onblur="this.placeholder = ' Email Address '" />
                                                        <div class="form-icon">
                                                            <button type="submit" name="submit" id="newsletter-submit" class="email_icon newsletter-submit button-contactForm"><FaLocationArrow /></button>
                                                        </div>
                                                        <div class="mt-10 info"></div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            
                        </div>
                    </div>
                </div>
                {/* <!-- Footer End--> */}

            </footer>




            <footer>

                <div class="footer-main">
                    <div class="footer-area pb-50 pt-25 hb-border-top">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-6 col-xl-6">
                                    <div class="footer-copy-right">
                                        <p>
                                            Copyright &copy;{new Date().getFullYear()} All rights reserved | <Link to="/">HashedBit Innovations Pvt Ltd</Link>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-6">
                                    <div className="module right">
                                        <ul class="list-inline social-list mb24">
                                            <li>
                                                <a class="icon-tilinkedin" href="https://www.linkedin.com/company/hashedbit-innovations/" target="_blank"><FaLinkedin /></a>
                                            </li>
                                            <li>
                                                <a class="icon-tifacebook" href="https://www.facebook.com/hashedbitinnovations" target="_blank"><FaFacebookSquare /></a>
                                            </li>
                                            <li>
                                                <a class="icon-titwitter" href="https://twitter.com/HashedBit" target="_blank"><FaTwitterSquare /></a>
                                            </li>
                                            <li>
                                                <a class="icon-tiinstagram" href="https://www.instagram.com/hashedbit/" target="_blank"><FaInstagramSquare /></a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
