import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { Link } from 'react-router-dom';

import RecentBlogs from './blogsubpages/RecentBlogs';
import BlogCategories from './blogsubpages/BlogCategories';

const BlogsCategory = () => {

    const { categoryname } = useParams();
    const [blogsData, setBlogsData] = useState([]);
    const [categories, setcategories] = useState([{ name: '', displayname: '', count: 0 }, { name: '', displayname: '', count: 0 }]);
    const [recentPosts, setRecentPosts] = useState([]);
    const [categoryfullname, setcategoryfullname] = useState({});

    useEffect(() => {
        let temp = categories.filter((item) => item.name === categoryname)
        setcategoryfullname(temp[0]);
    }, [categories])

    const fetchBlogsByCategory = async (catname) => {
        //console.log('catname', catname, typeof(catname));
        const url = process.env.REACT_APP_API_URL + 'blog/categoryblogs/' + catname;

        try {
            //const response = await fetch(url);
            const response = await axios.get(url);
            //console.log(response.data);
            setBlogsData(response.data)
        }
        catch (err) {
            console.log('Error In API Call - ', err)
        }
    }

    const fetchCategories = async () => {
        const url = process.env.REACT_APP_API_URL + 'category/fetchallcategories';
        try {
            const response = await axios.get(url);
            console.log(response.data);
            setcategories(response.data)
        }
        catch (err) { console.log('Error In API Call - ', err) }
    }

    const fetchRecentPosts = async () => {
        const url = process.env.REACT_APP_API_URL + 'blog/recentblogs';
        try {
            const response = await axios.get(url);
            //console.log(response.data);
            setRecentPosts(response.data)
        }
        catch (err) { console.log('Error In API Call - ', err) }
    }

    useEffect(() => {
        fetchBlogsByCategory(categoryname);
        fetchCategories();
        fetchRecentPosts();
    }, [])

    // useEffect(() => {
    //     console.log('blogsData', blogsData)
    // })

    return (

        <>

            <div class="services-area">
                <div class="container">

                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-8">
                            <div class="section-tittle text-center mb-80">
                                <h2>Thoughts, Ideas and Tech Articles : {categoryfullname && categoryfullname.displayname}​</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="blog_area section-paddingr">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 mb-5 mb-lg-0">
                            <div class="blog_left_sidebar">
                                {
                                    blogsData && blogsData.map((item, index) => (
                                        <article class="blog_item">
                                            <div class="blog_item_img">
                                                {/* <img class="card-img rounded-0" src={blogImage} alt="" /> */}
                                                {/* <img src={require(`../assets/img/blog/articles/${item.featuredimage}`)} class="img-fluid" alt="post" /> */}
                                                {/* <Link to={`/blog/${item.postid}`} class="blog_item_date">
                                                    <h3>15</h3>
                                                    <p>Jan</p>
                                                </Link> */}
                                            </div>

                                            <div class="blog_details">
                                                <Link class="d-inline-block" to={`/blog/${item.item.srno}`} >
                                                    <h2>{item.postheading}</h2>
                                                </Link>
                                                <p className='blogs-content'>
                                                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                                </p>
                                                <Link class="d-inline-block" to={`/blog/${item.srno}`} >
                                                    <div>Read more ...</div>
                                                </Link>
                                                <div class="blog-info-link mt-4">
                                                    <span>{item.category}</span>
                                                </div>
                                                {/* <ul class="blog-info-link">
                                                    <li><a href="#" className='bg-warning bg-opacity-10 px-2'><i class="fa fa-user"></i>{item.category}</a></li>
                                                </ul> */}
                                            </div>
                                        </article>
                                    ))
                                }

                                {/* <nav class="blog-pagination justify-content-center d-flex">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a href="#" class="page-link" aria-label="Previous">
                                                <i class="ti-angle-left"></i>
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a href="#" class="page-link">1</a>
                                        </li>
                                        <li class="page-item active">
                                            <a href="#" class="page-link">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a href="#" class="page-link" aria-label="Next">
                                                <i class="ti-angle-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="blog_right_sidebar">
                                {/* <aside class="single_sidebar_widget search_widget">
                                    <form action="#">
                                        <div class="form-group">
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control" placeholder='Search Keyword'
                                                    onfocus="this.placeholder = ''"
                                                    onblur="this.placeholder = 'Search Keyword'" />
                                                <div class="input-group-append">
                                                    <button class="btns" type="button"><i class="ti-search"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn"
                                            type="submit">Search</button>
                                    </form>
                                </aside> */}

                                <BlogCategories categories={categories} />

                                <RecentBlogs recentPosts={recentPosts} />

                                {/* <aside class="single_sidebar_widget post_category_widget">
                                    <h4 class="widget_title">Blog Categories</h4>
                                    <ul class="list cat-list">
                                        {
                                            categories.map((item, index) => (
                                                <li key={index}>
                                                    <Link to={`/blogs/category/${item.name}`} href="#" class="d-flex">
                                                        <p>{item.displayname}</p>
                                                        <p>&nbsp;({item.count})</p>
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </aside>
                                <aside class="single_sidebar_widget popular_post_widget">
                                    <h3 class="widget_title">Recent Posts</h3>
                                    {
                                        recentPosts.map((item, index) => (
                                            <div class="media post_item">
                                                
                                                <div class="media-body">
                                                    <Link to={`/blog/${item.postid}`}>
                                                        <h3>{item.postheading}</h3>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </aside> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogsCategory
