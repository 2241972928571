import React, { useState } from 'react';
// import { Formik, Field, Form } from "formik";
import hms from '../assets/img/products/Hospital_management_system.png';
import sms from '../assets/img/products/School_management_system.png';
import emp from '../assets/img/products/employee.png';
import hotel from '../assets/img/products/hotel.png';
import { Link } from 'react-router-dom';

const Products = () => {

    // const [apistatus, setApistatus] = useState(false);
    // const [loader, setLoader] = useState(false);
    // const [error, setError] = useState(false);

    // const formikInitialValues = {
    //     name: "",
    //     email: "",
    //     subject: "",
    //     message: ""
    // }

    // const submitContact = async (values) => {
    //     setLoader(true);
    //     console.log(values);
    //     const url = process.env.REACT_APP_API_URL + 'contact/register';

    //     try {
    //         const response = await fetch(url, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify(values),
    //         });

    //         if (response.status === 200) {
    //             setApistatus(true);
    //             setLoader(false);
    //             return {
    //                 error: false
    //             }
    //         }
    //         else {
    //             setError(true);
    //             setLoader(false);
    //             return {
    //                 error: true
    //             }
    //         }
    //     }
    //     catch (err) {
    //         setError(true);
    //         setLoader(false);
    //         console.log('Error In API Call - ', err)
    //     }
    // }

    return (
        <>

            <div class="services-area">
                <div class="container">

                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-8">
                            <div class="section-tittle text-center mb-80">
                                <h2>Our Products​</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section class="contact-section bg-home3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <img src={sms} alt='School Management System' className='img-responsive'/>
                        </div>

                        <div class="col-lg-6">
                            <h4>School Management Software</h4>
                            <br></br>
                            <p>
                                Transform your school's management process through our school management system. It is a cloud-based system that eases the management of all the data from student details to exam results and tasks like admission and staff management. It provides a dashboard to access data.
                                <br></br><br></br>
                                School administration has the responsibility of managing all the affairs, but it's a tedious task and errors are inevitable in handling huge amounts of data. Our school's management system is a boon for the management people, it makes their work hassle-free and enhances work productivity.
                            </p>

                            <Link to="/schoolManagement"><button className='btn btn-success' >Call for more info</button></Link>

                        </div>
                    </div>
                </div>
            </section>


            <section class="contact-section bg-home4">
                <div class="container">
                    <div class="row">


                        <div class="col-lg-6">
                            <h4>Hospital Management Software</h4>
                            <br></br>
                            <p>
                                Manage healthcare operations effectively with our advanced hospital management system. The customizable, AI-based software helps you manage every aspect of your hospital or clinic. It provides end-to-end management, from patients and billing to laboratories and OT, and enables hospital administration to have centralized control of all the activities.
                                <br></br><br></br>
                                Switch to automation with our state-of-the-art HMS and manage all the processes with efficiency. This modern technology software allows you to easily control daily hospital activities and provides insightful reports and analytics. The cloud-based system safely stores all the data so that you can access any information, in no time. It digitizes the processes, reducing the hassle of handling papers and enabling fast and effective services.
                            </p>

                            <Link to="/hospitalManagement"><button className='btn btn-success' >Call for more info</button></Link>

                        </div>
                        <div class="col-lg-6">
                            <img src={hms} alt='Hospital Management System' className='img-responsive'/>
                        </div>
                    </div>
                </div>
            </section>

            <section class="contact-section bg-home3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <img src={emp} alt='Employee Management System' className='img-responsive' />
                        </div>
                        <div class="col-lg-6">
                            <h4>Employee Management System</h4>
                            <br></br>
                            <p>
                                Our Employee Management System i.e. EmpHub is a software to manage to all employee related works in an organisation. It solves the problems of admin, hr, employee, departments heads and company management.
                            </p>
                            <Link to="/employeeManagement"><button className='btn btn-success' >Call for more info</button></Link>
                        </div>
                    </div>
                </div>
            </section>

            <section class="contact-section bg-home4">
                <div class="container">
                    <div class="row">


                        <div class="col-lg-6">
                            <h4>Hotel Management Software</h4>
                            <br></br>
                            <p>
                                Out Hotel Management Software is to ease the day to day activities of hotel operations. It is to be used by the hotel owners, staffs, reception desk to management of rooms, booking and services. It is also enabled for handling online bookings.
                            </p>

                            <Link to="/hotelManagement"><button className='btn btn-success' >Call for more info</button></Link>

                        </div>
                        <div class="col-lg-6">
                            <img src={hotel} alt='Hotel Management Portal' className='img-responsive'/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Products
