import React, { useState } from 'react';
import { Formik, Field, Form } from "formik";
import loadImage from '../assets/img/giphy.gif';

const Contact = () => {

    const [apistatus, setApistatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);

    const formikInitialValues = {
        name: "",
        email: "",
        subject: "",
        message: "",
        contact: ""
    }

    const submitContact = async (values) => {
        setLoader(true);
        console.log(values);
        const url = process.env.REACT_APP_API_URL + 'contact/register';

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            });
            //const response = await axios.post(url, values);
            //console.log(response);

            if (response.status === 200) {
                setApistatus(true);
                setLoader(false);
                return {
                    error: false
                }
            }
            else {
                setError(true);
                setLoader(false);
                return {
                    error: true
                }
            }
        }
        catch (err) {
            setError(true);
            setLoader(false);
            console.log('Error In API Call - ', err)
        }
    }

    return (
        <>

            <div class="services-area">
                <div class="container">

                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-8">
                            <div class="section-tittle text-center mb-80">
                                <h2>Contact Us​</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section class="contact-section bg-home">
                <div class="container">
                    <div class="d-none d-sm-block mb-5 pb-4">
                        <div id="map"></div>

                        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDpfS1oRGreGSBU5HHjMmQ3o5NLw7VdJ6I&amp;callback=initMap">
                        </script>

                    </div>


                    <div class="row">
                        <div class="col-12">
                            <h2 class="contact-title">Get in Touch</h2>
                        </div>
                        <div class="col-lg-8">

                            <Formik
                                initialValues={formikInitialValues}
                                onSubmit={async (values, { resetForm }) => {
                                    //console.log(values);
                                    const { error } = await submitContact(values);
                                    if (!error) {
                                        resetForm();
                                    };
                                }}
                            >
                                <Form>
                                    <br></br>
                                    <div className="form-group row">
                                        <div className='col-sm-6'>
                                            <Field name="name" type="text" className="form-control" placeholder="Your Full Name" required />
                                        </div>

                                        <div className='col-sm-6'>
                                            <Field name="email" type="email" className="form-control" placeholder="Enter Your Email" required />
                                        </div>
                                    </div>
                                    <br></br>

                                    <div className="form-group row">
                                        <div className='col-sm-12'>
                                            <Field name="subject" type="text" className="form-control" placeholder="Subject" required />
                                        </div>
                                    </div>
                                    <br></br>

                                    <div className="form-group row">
                                        <div className='col-sm-12'>
                                            <Field name="message" type="text" className="form-control" placeholder="Type Your Message Here" required/>
                                        </div>
                                    </div>
                                    <br></br>

                                    <div className="form-group row">
                                        <div className='col-sm-12'>
                                            <Field name="Contact" type="number" className="form-control" placeholder="Contact" required />
                                        </div>
                                    </div>
                                   <br></br>
                                    <button type="submit" className='btn btn-success'>Submit</button>

                                    <br></br>
                                    <br></br>
                                </Form>
                            </Formik>

                            {
                                apistatus && (
                                    <div className="alert alert-success" role="alert">
                                        Your form has been submitted successfully.
                                    </div>
                                )
                            }

                            {
                                error && (
                                    <div className="alert alert-danger" role="alert">
                                        Server Error. Please, try after sometime.
                                    </div>
                                )
                            }

                        </div>

                        {loader && (
                            <div className="loading">
                                <img src={loadImage} alt="Loader" />
                            </div>
                        )
                        }


                        <div class="col-lg-3">
                            <div class="media contact-info">
                                {/* <span class="contact-info__icon"><i class="ti-home"></i></span> */}
                                <div class="media-body">
                                    <h3>Have an inquiry or some feedback for us? Fill out the form.</h3>
                                    {/* <p>Have an inquiry or some feedback for us? Fill out the form.</p> */}
                                </div>
                            </div>
                            <div class="media contact-info">
                                {/* <span class="contact-info__icon"><i class="ti-tablet"></i></span> */}
                                <div class="media-body">

                                    <p>Call - Mon to Fri 9am - 7pm</p>
                                    <h3>+91 9669787936, +91 9599171535</h3>
                                </div>
                            </div>
                            <div class="media contact-info">
                                {/* <span class="contact-info__icon"><i class="ti-email"></i></span> */}
                                <div class="media-body">

                                    <p>Send us your query anytime!</p>
                                    <h3>info@hashedbit.com, hashedbit@gmail.com</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact
