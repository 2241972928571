import React, { useState, useEffect } from 'react';
import logo from '../assets/img/CodeAce-Header.png';
import { Link, useLocation } from 'react-router-dom';
import { FaLinkedin, FaFacebookSquare, FaTwitterSquare, FaInstagramSquare, FaMobileAlt, FaRegEnvelope } from "react-icons/fa";

const Header = () => {

    const location = useLocation();
    const [url, setUrl] = useState('a a');
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    const checkBlog = () => {
        const blogPages = ['blogs', 'blog'];
        const temp = url.split('/')[1];
        if(blogPages.indexOf(temp) > -1) {return true;}
        else {return false;}
    }

    const checkServices = () => {
        const blogPages = ['services', 'service'];
        const temp = url && url.split('/')[1];
        if(blogPages.indexOf(temp) > -1) {return true;}
        else {return false;}
    }

    const checkProduct = () => {
        const blogPages = ['products', 'product'];
        const temp = url.split('/')[1];
        if(blogPages.indexOf(temp) > -1) {return true;}
        else {return false;}
    }

    const checkLearning = () => {
        const blogPages = ['learning'];
        const temp = url.split('/')[1];
        if(blogPages.indexOf(temp) > -1) {return true;}
        else {return false;}
    }

    return (
        // <div className='row secureheader'>
        //     <div className='col-12 bg-info bg-opacity-50 text-center'>
        //       <h3>HashedBit Innovations</h3>
        //     </div>
        //   </div>

        <header>
            {/* <!-- Header Start --> */}
            <div class="header-area header-transparrent ">
                <div class="main-header header-sticky">
                    <div className='row'>
                        <div className='col-xl-12 col-md-12 topbar-bg'>
                            <div class="top-menu-bar container" >
                                <div className=''>
                                    <div className="module left">
                                        <span class="sub"><FaMobileAlt /> 9669787936</span>
                                    </div>
                                    <div className="module left">
                                        <span class="sub"><FaRegEnvelope /> hashedbit@gmail.com</span>
                                    </div>
                                    <div className="module left">
                                        <span class="sub">| &nbsp; <a href='https://learning.hashedbit.com/home' target='_blank'>Learning Login</a></span>
                                    </div>
                                    <div className="module right">
                                        <ul class="list-inline social-list mb24">
                                            <li>
                                                <a class="icon-tilinkedin" href="https://www.linkedin.com/company/hashedbit-innovations/" target="_blank"><FaLinkedin /></a>
                                            </li>
                                            <li>
                                                <a class="icon-tifacebook" href="https://www.facebook.com/hashedbitinnovations" target="_blank"><FaFacebookSquare /></a>
                                            </li>
                                            <li>
                                                <a class="icon-titwitter" href="https://twitter.com/HashedBit" target="_blank"><FaTwitterSquare /></a>
                                            </li>
                                            <li>
                                                <a class="icon-tiinstagram" href="https://www.instagram.com/hashedbit/" target="_blank"><FaInstagramSquare /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='container'>
                        <div class="row align-items-center">
                            <div class="col-xl-3 col-lg-3 col-md-3">
                                <div class="logo">
                                    <Link to="/">
                                        <img src={logo} alt="" className='img-fluid' />
                                    </Link>
                                </div>
                            </div>
                            <div class="col-xl-9 col-lg-9 col-md-9">
                                {/* <!-- Main-menu --> */}
                                <div class="main-menu f-right d-none d-lg-block">
                                    <nav>
                                        <ul id="navigation">
                                            <li class={url === '/' && 'active'}><Link to='/'>Home</Link></li>
                                            <li class={url === '/about' && 'active'}><Link to='/about'>About</Link></li>
                                            <li class={checkServices() && 'active'}><Link to='/services'>Services</Link></li>
                                            <li class={checkProduct() && 'active'}><Link to='/products'>Products</Link></li>
                                            <li class={checkLearning() && 'active'}><Link to='/learning'>Learning</Link></li>
                                            <li class={url === '/career' && 'active'}><Link to='/career'>Career</Link></li>
                                            <li class={checkBlog() && 'active'}><Link to='/blogs'>Blog</Link></li>
                                            <li class={url === '/contact' && 'active'}><Link to='/contact'>Contact</Link></li>
                                            
                                            {/* <li><a href='https://learning.hashedbit.com/home' target='_blank'>Learning Login</a></li> */}

                                            {/* <li class="active"><a href="index.html"> Home</a></li>
                                            <li><a href="feature.html">Feature</a></li>
                                            <li><a href="services.html">Services</a></li>
                                            <li><a href="pricing.html">Pricing</a></li>
                                            <li><a href="#">Pages</a>
                                                <ul class="submenu">
                                                    <li><a href="blog.html">Blog</a></li>
                                                    <li><a href="single-blog.html">Blog Details</a></li>
                                                    <li><a href="elements.html">Element</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="contact.html">Contact</a></li> */}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            {/* <!-- Mobile Menu --> */}
                            <div class="col-12">
                                <div class="mobile_menu d-block d-lg-none"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Header End --> */}
        </header >
    )
}

export default Header
