import React from 'react';
import { Link } from 'react-router-dom';

const RecentBlogs = (props) => {
    const { recentPosts } = props;
    return (
        <>

            <aside class="single_sidebar_widget popular_post_widget">
                <h3 class="widget_title">Recent Posts</h3>
                {
                    recentPosts.map((item, index) => (
                        <div class="media post_item">

                            {/* Featured Image */}
                            {/* <img src={require(`../assets/img/blog/articles/${item.featuredimage}`)} alt="post" /> */}
                            <div class="media-body">
                                <Link to={`/blog/${item.srno}`}>
                                    <h3>{item.postheading}</h3>
                                </Link>
                            </div>
                        </div>
                    ))
                }
            </aside>


        </>
    )
}

export default RecentBlogs
