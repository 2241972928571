import React from "react";
import hotel from "../../assets/img/products/hotel.png";
import "bootstrap/dist/css/bootstrap.min.css";

const HotelManagement = () => {
  return (
    <>
      <div className="contact-section bg-home3 py-6">
        <div className="container my-5">
          <div className="row  ">
            <div className="col  ">
              <h1 className="text-start fw-bold  ">
                Hotel <br /> Management System
              </h1>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <div className="col-lg-6 right img-fluid ">
              <div className="pe-0">
                <img
                  src={hotel}
                  alt="img"
                  className="img-responsive"
                  style={{ maxWidth: "90%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-light py-5 ">
        <div className="container">
          <div className="row  py-5">
            <div className="col  ">
              <div className=" ps-5 pe-3">
                <div className=" " style={{maxWidth: " 600px"}}>
                  <span className="text-danger ">Product</span>
                  <h1 className="fw-bold mb-4 ">School Management System</h1>
                  <p className="text-wrap text-justify">
                    Transform your school's management process through our
                    school management system. It is a cloud-based system that
                    eases the management of all the data from student details to
                    exam results and tasks like admission and staff management.
                    It provides a dashboard to access data.
                  </p>
                  <p className="text-wrap text-justify">
                    School administration has the responsibility of managing all
                    the affairs, but it's a tedious task and errors are
                    inevitable in handling huge amounts of data. Our school's
                    management system is a boon for the management people, it
                    makes their work hassle-free and enhances work productivity.
                  </p>
                  <p className="text-wrap text-justify">
                    We curate management systems that suit the requirements of
                    the institute and then digitize their day-to-day processes.
                    With multiple modules and seamless functioning, and secure
                    operations, we deliver the best solution for school
                    management.
                  </p>
                  <p className="text-wrap text-justify">
                    It not only makes the process easy but also saves money by
                    effectively managing the fees and expenses and enabling the
                    management people to track the finances and analyze the
                    expenditures.
                  </p>
                </div>
              </div>
            </div>
            <div className="col mt-3 ms-5" style={{ maxWidth: "500px" }}>
              <div className="shadow-lg p-3 bg-white rounded-5 ">
                <h2 className="fw-bold mt-4">Schedule Appointment</h2>
                <p>We here to help you 24/7 with experts</p>
                <form action="" method="">
                  <div className=" my-4">
                    <label>Name</label>
                    <input
                      type="text"
                      required=""
                      className="form-control"
                      name="contact_name"
                      placeholder="Name"
                    />
                  </div>
                  <div className=" mt-4">
                    <label>Email</label>
                    <input
                      type="email"
                      required=""
                      className="form-control"
                      name="contact_email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Phone</label>
                    <input
                      type="tel"
                      required=""
                      className="form-control"
                      name="contact_phone"
                      placeholder="+91"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Your Query</label>
                    <textarea
                      type="Subject"
                      required=""
                      className="form-control"
                      name="message"
                      placeholder="Your Query"
                    ></textarea>
                  </div>

                  {/* <!-- div to show reCAPTCHA --> */}
                  <div
                    className="g-recaptcha mt-4"
                    data-sitekey="6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r"
                  >
                    <div style={{ width: "304px", height: "78px" }}>
                      <div>
                        <iframe
                          title="reCAPTCHA"
                          width="304"
                          height="78"
                          role="presentation"
                          name="a-tnvqec2kjuv"
                          frameborder="0"
                          scrolling="no"
                          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                          src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r&amp;co=aHR0cHM6Ly9jb3Jld2F2ZS5pbzo0NDM.&amp;hl=en&amp;v=1kRDYC3bfA-o6-tsWzIBvp7k&amp;size=normal&amp;cb=rwx58wbrxjrt"
                        ></iframe>
                      </div>
                      <textarea
                        id="g-recaptcha-response-1"
                        name="g-recaptcha-response"
                        className="g-recaptcha-response"
                        style={{
                          width: " 250px",
                          height: "40px",
                          border: "1px solid rgb(193, 193, 193)",
                          margin: " 10px 25px",
                          padding: " 0px",
                          resize: "none",
                          display: " none",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <br />

                  <div className=" d-flex justify-content-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg mx-1 "
                      style={{ width: "280px" }}
                      name="submit_btn"
                      value="Submit Now"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section px-4 py-5 bg-dark">
        <div className="container">
          <div className=" mt-5 ">
            <h2 className="display-5 fw-bold text-white">Features</h2>
          </div>
          <div className="row gap-0 row-gap-5 py-5">
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white "> Great customer experience</h5>
                  <p>
                    A simple and easy navigation design helps you clearly
                    display your menu and details and provides a good user
                    experience. Customers can easily browse and order their
                    favorite dishes. They can also add items to the cart and
                    mark them as favorites. The feedback options allow customers
                    to give ratings and suggestions. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid active">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Get the best restaurant POS</h5>
                  <p>
                    Our POS software assists you in managing your restaurant
                    efficiently and enables you to grow and expand. It Improves
                    billings possess and enhances overall operational efficiency
                    through advanced features. You can manage everything from
                    what your customers see to your orders and inventory from
                    your dashboard.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white">
                    {" "}
                    Production and recipe management
                  </h5>
                  <p>
                    Improve accuracy in your kitchen. Take control of your
                    recipes by mapping them to their ingredients to ensure a
                    consistent taste. Manage prices effectively by calculating
                    production costs incurred in the most simple way using our
                    restaurant software
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Intelligent analytics</h5>
                  <p>
                    Simple and actionable reports to manage your day-to-day
                    operations smoothly. You can generate real-time reports to
                    analyze your business trends to help you make better
                    decisions. Get custom reports on sales, ingredients usage,
                    and daily pilferage.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white">Manage inventory</h5>
                  <p>
                    The restaurant management system allows you to efficiently
                    manage your inventory by ordering on time and reducing
                    wastage. You can monitor the stock and place orders. The
                    system helps you in maintaining your inventory so that you
                    never fall short of your primary supplies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white">Manage inventory</h5>
                  <p>
                    The restaurant management system allows you to efficiently
                    manage your inventory by ordering on time and reducing
                    wastage. You can monitor the stock and place orders. The
                    system helps you in maintaining your inventory so that you
                    never fall short of your primary supplies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="  py-5 ps-5  ">
        <div className="container">
          <div className="row">
            <h4 className="text-danger"> why choose us</h4>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>360 Approach</h4>
              <p className="text-wrap text-justify">
                End-to-end hospital management solutions with dynamic modules to
                meet every need for efficient hospital operations and
                comprehensive management.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Tested and Proven</h4>
              <p>
                Corewave’s HMS is developed and tested by highly experienced
                professionals to ensure flawless and reliable functioning.
                Trusted by reputed healthcare providers we are best-in-class
                management software
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Highly Scalable</h4>
              <p>
                A scalable software to provide you best solutions as you grow.
                Our solution expands along with your working needs.{" "}
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>User friendly</h4>
              <p>
                Designed to suit customer needs, our HMS is flexible and easy to
                use. Functions are included while keeping the requirements in
                mind to provide maximum efficiency.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Cost effective</h4>
              <p>
                A highly useful and robust software at the most reasonable cost.
                We aim to provide the best technology solutions at the best
                prices.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Join the transformation</h4>
              <p>
                We are eager to support the crucial process of digital
                transformation within your healthcare organization. For all your
                software requirements we are here to provide you most relevant
                solutions
              </p>
            </div>
            <h4 className="my-3">Customise solution</h4>
            <h4 className="my-3">Quick execution</h4>
            <h4 className="my-3">Complete the Test and Display</h4>
            <h4 className="mt-3 mb-5">Speedy Setup</h4>
          </div>
        </div>
      </section>

      <div className="  bg-info bg-gradient px-4 py-5  text-center">
        <p className="text-success-emphasis mb-2">Let's Work Together</p>
        <h1 className="display-5 fw-bold text-info text-body-emphasis py-5">
          Need A Succesful Project?
        </h1>
        <div className="col-lg-6 mx-auto py-2">
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button type="button" className="btn btn-primary btn-lg px-5 gap-3">
              Estimate project
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default HotelManagement;
