import React, { useEffect } from 'react';
import './App.css';
import './template.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './customstyle.css';
import axios from 'axios';

import Header from './shared/Header';
import Footer from './shared/Footer';

import Home from './landing/Home';
import About from './landing/About';
import Services from './landing/Services';
import Contact from './landing/Contact';
import Career from './landing/Career';
import Blog from './landing/Blog';
import BlogDetail from './landing/BlogDetail';
import BlogsCategory from './landing/BlogsCategory';
import Learning from './landing/Learning';
import Products from './landing/Products';

import CourseMern from './landing/courses/CourseMern';
import CoursePlacement from './landing/courses/CoursePlacement';
import CourseRegistration from './landing/courses/CourseRegistration';
import CourseInternship from './landing/courses/CourseInternship';
import MBAInternship from './landing/courses/MBAInternship';
import MockInterview from './landing/courses/MockInterview';
import CollegeInternship from './landing/courses/CollegeInternship';
import CollegeWorkshop from './landing/courses/CollegeWorkshop';
import MBAPaidInternship from './landing/courses/MBAPaidInternship';
import CorporateTraining from './landing/courses/CorporateTraining';
import CampusAmbassador from './landing/courses/CampusAmbassador';
import HotelManagement from './landing/productsdetail/HotelManagement';
import SchoolManagement from './landing/productsdetail/SchoolManagement';
import HospitalManagement from './landing/productsdetail/HospitalManagement';
import EmployeeManagement from './landing/productsdetail/EmployeeManagement';
import Verification from './landing/courses/Verification';

function App() {

  //temp code to keep server live
  // const callApiQsList = async () => {
  //   try {
  //     const url = process.env.REACT_APP_API_URL + 'blog/recentblogs';
  //     const response = await axios.get(url);
  //   }
  //   catch (error) { console.log(error); }
  // }
  // useEffect(() => {
  //   setInterval(() => callApiQsList(), 10000)
  // }, [])
  //temp code to keep server live

  return (
    <div className='container-fluid zero-margin'>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/home' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/products' element={<Products />} />
          <Route path='/employeeManagement' element={<EmployeeManagement />} />
          <Route path='/hotelManagement' element={<HotelManagement />} />
          <Route path='/schoolManagement' element={<SchoolManagement />} />
          <Route path='/hospitalManagement' element={<HospitalManagement />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/career' element={<Career />} />
          <Route path='/blogs' element={<Blog />} />
          <Route path='/blog/:blogid' element={<BlogDetail />} />
          <Route path='/blogs/category/:categoryname' element={<BlogsCategory />} />
          <Route path='/learning' element={<Learning />} />
          <Route path='/learning/internship' element={<CourseInternship />} />
          <Route path='/learning/mbainternship' element={<MBAInternship />} />
          <Route path='/learning/mbapaidinternship' element={<MBAPaidInternship />} />
          <Route path='/learning/mern' element={<CourseMern />} />
          <Route path='/learning/placement' element={<CoursePlacement />} />
          <Route path='/learning/mockinterview' element={<MockInterview />} />
          <Route path='/learning/collegeinternship' element={<CollegeInternship />} />
          <Route path='/learning/corporatetraining' element={<CorporateTraining />} />
          <Route path='/learning/collegeworkshop' element={<CollegeWorkshop />} />
          <Route path='/learning/campusambassador' element={<CampusAmbassador />} />
          <Route path='/learning/registration/:coursename' element={<CourseRegistration />} />
          <Route path='/verification' element={<Verification />} />
          <Route path='/' element={<Home />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
