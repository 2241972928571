import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
const About = () => {
  return (
    <>
      {/* <div className="row">
        <div className="business-verticals">
          <div className="business-verticals-title">
            <h2>Our Business Verticals</h2>
            <p>
              HashedBit Innovations work at some of the leading and innovative{" "}
              <br /> organizations of today, solving core business problems.
            </p>
          </div>
        </div>
        <div className="business-verticals-cards">
          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            pagination={{
              type: "fraction",
            }}
            navigation={true}
            modules={[Navigation]}
            className="my-business-verticals-swiper"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1,
              },
              700: {
                slidesPerView: 2,
              },
              1000: {
                slidesPerView: 3,
              },
              1700: {
                slidesPerView: 4,
              },
            }}
          >
            <SwiperSlide>
              <div className="card-style">
                <div className="business-verticals-card-image-style">
                  <img
                    src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                    className="business-verticals-image-style"
                    alt="Card Img"
                  />
                </div>
                <div className="card-body">
                  <h5 className="business-verticals-card-title">
                    Development Team
                  </h5>
                  <p className="card-text business-verticals-card-text">
                    Development team consisting of graduates from NITs and IITs.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-style">
              <div className="business-verticals-card-image-style">
                  <img
                    src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                    className="business-verticals-image-style"
                    alt="Card Img"
                  />
                </div>
                <div className="card-body">
                  <h5 className="business-verticals-card-title">
                    Software Solutions
                  </h5>
                  <p className="card-text business-verticals-card-text">
                    Availability of all types of Web & Software Solutions.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-style">
              <div className="business-verticals-card-image-style">
                  <img
                    src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                    className="business-verticals-image-style"
                    alt="Card Img"
                  />
                </div>
                <div className="card-body">
                  <h5 className="business-verticals-card-title">
                    Minimum Time & Cost
                  </h5>
                  <p className="card-text business-verticals-card-text">
                    Minimum development charge and time taken for the products
                    as compared to others.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-style">
              <div className="business-verticals-card-image-style">
                  <img
                    src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                    className="business-verticals-image-style"
                    alt="Card Img"
                  />
                </div>
                <div className="card-body">
                  <h5 className="business-verticals-card-title">
                    Add On Services
                  </h5>
                  <p className="card-text business-verticals-card-text">
                    Free Add-On like SEO, Responsive, Unlimited Pages and
                    Design.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-style">
              <div className="business-verticals-card-image-style">
                  <img
                    src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                    className="business-verticals-image-style"
                    alt="Card Img"
                  />
                </div>
                <div className="card-body">
                  <h5 className="business-verticals-card-title">
                    Latest Technologies
                  </h5>
                  <p className="card-text business-verticals-card-text">
                    Latest Technologies based services for Business Development
                    available here.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-style">
              <div className="business-verticals-card-image-style">
                  <img
                    src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                    className="business-verticals-image-style"
                    alt="Card Img"
                  />
                </div>
                <div className="card-body">
                  <h5 className="business-verticals-card-title">
                    Project Maintenance
                  </h5>
                  <p className="card-text business-verticals-card-text">
                    Proper responsibility of Project Maintenance by Company.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-style">
              <div className="business-verticals-card-image-style">
                  <img
                    src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                    className="business-verticals-image-style"
                    alt="Card Img"
                  />
                </div>
                <div className="card-body">
                  <h5 className="business-verticals-card-title">
                    Business Deveopment
                  </h5>
                  <p className="card-text business-verticals-card-text">
                    Business Development Consultation & Online Social Marketing.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-style">
              <div className="business-verticals-card-image-style">
                  <img
                    src="https://barrownzlearning.com/Content/img/gallery/BLA(1).png"
                    className="business-verticals-image-style"
                    alt="Card Img"
                  />
                </div>
                <div className="card-body">
                  <h5 className="business-verticals-card-title">
                    Business Analytics
                  </h5>
                  <p className="card-text business-verticals-card-text">
                    Saving clients time and money by using company analytics &
                    latest technologies.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div> */}
      
      <div class="services-area">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-8">
              <div class="section-tittle text-center mb-80">
                <h2>Why Us ???​</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="best-features-area">
        <div class="container">
          <div class="row justify-content-end">
            <div class="col-xl-12 col-lg-12">
              {/* <div class="row">
                <div class="col-lg-10 col-md-10">
                  <div class="section-tittle text-center">
                    <h2>Why Us ???</h2>
                  </div>
                </div>
              </div> */}
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 border-primary">
                  <div class="single-features mb-70">
                    <div class="features-icon">
                      <span class="flaticon-support"></span>
                    </div>
                    <div class="features-caption">
                      <h3>
                        <br></br>Development Team
                      </h3>
                      <p>
                        Development team consisting of graduates from NITs and
                        IITs.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="single-features mb-70">
                    <div class="features-icon">
                      <span class="flaticon-support"></span>
                    </div>
                    <div class="features-caption">
                      <h3>
                        <br></br>Software Solutions
                      </h3>
                      <p>
                        Availability of all types of Web & Software Solutions.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="single-features mb-70">
                    <div class="features-icon">
                      <span class="flaticon-support"></span>
                    </div>
                    <div class="features-caption">
                      <h3>Minimum Time & Cost</h3>
                      <p>
                        Minimum development charge and time taken for the
                        products as compared to others.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="single-features mb-70">
                    <div class="features-icon">
                      <span class="flaticon-support"></span>
                    </div>
                    <div class="features-caption">
                      <h3>Add On Services</h3>
                      <p>
                        Free Add-On like SEO, Responsive, Unlimited Pages and
                        Design.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="single-features mb-70">
                    <div class="features-icon">
                      <span class="flaticon-support"></span>
                    </div>
                    <div class="features-caption">
                      <h3>Latest Technologies</h3>
                      <p>
                        Latest Technologies based services for Business
                        Development available here.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="single-features mb-70">
                    <div class="features-icon">
                      <span class="flaticon-support"></span>
                    </div>
                    <div class="features-caption">
                      <h3>Project Maintenance</h3>
                      <p>
                        Proper responsibility of Project Maintenance by Company.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="single-features mb-70">
                    <div class="features-icon">
                      <span class="flaticon-support"></span>
                    </div>
                    <div class="features-caption">
                      <h3>Business Deveopment</h3>
                      <p>
                        Business Development Consultation & Online Social
                        Marketing.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="single-features mb-70">
                    <div class="features-icon">
                      <span class="flaticon-support"></span>
                    </div>
                    <div class="features-caption">
                      <h3>Business Analytics</h3>
                      <p>
                        Saving clients time and money by using company analytics
                        & latest technologies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="features-shpae features-shpae2 d-none d-lg-block">
          <img src="assets/img/shape/best-features.png" alt="" />
        </div>
      </section>

      <div class="our-customer pt-200 pb-100 bg-curve">
        <div class="container-fluid">
          <div class="our-customer-wrapper">
            <div class="row d-flex justify-content-center">
              <div class="col-xl-8">
                <div class="section-tittle text-center">
                  <h2>Let’s work and create together.</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="customar-active dot-style d-flex dot-style">
                  <div class="single-customer mb-100">
                    <div class="what-img">
                      <img src="assets/img/shape/man1.png" alt="" />
                    </div>
                    <div class="what-cap">
                      {/* <h4><a href="#">Welcome To The Best Model  Winner Contest</a></h4> */}
                      <p>
                        We are a bunch of highly technical & creative personnel
                        with innovative ideas and having a crazy attitude
                        towards our work; provides inexpensive yet effective
                        array of total web and software solutions. Along with
                        that we have a complete dedicated team for Training and
                        Workshop purposes. We have expert team of professionals
                        for different technologies who keep them updated with
                        recent innovations in the IT industry.
                      </p>
                    </div>
                  </div>

                  {/* <div class="single-customer mb-100">
                    <div class="what-img">
                      <img src="assets/img/shape/man2.png" alt="" />
                    </div>
                    <div class="what-cap">
                      <h4><a href="#">Welcome To The Best Model  Winner Contest</a></h4>
                      <p>Utenim ad minim veniam quisnostrud exercitation ullamcolabor nisiut aliquip ex ea commodo consequat duis aute irure dolor in represse.</p>
                    </div>
                  </div> */}

                  {/* <div class="single-customer mb-100">
                    <div class="what-img">
                      <img src="assets/img/shape/man3.png" alt="" />
                    </div>
                    <div class="what-cap">
                      <h4><a href="#">Welcome To The Best Model  Winner Contest</a></h4>
                      <p>Utenim ad minim veniam quisnostrud exercitation ullamcolabor nisiut aliquip ex ea commodo consequat duis aute irure dolor in represse.</p>
                    </div>
                  </div> */}

                  {/* <div class="single-customer mb-100">
                    <div class="what-img">
                      <img src="assets/img/shape/man2.png" alt="" />
                    </div>
                    <div class="what-cap">
                      <h4><a href="#">Welcome To The Best Model  Winner Contest</a></h4>
                      <p>Utenim ad minim veniam quisnostrud exercitation ullamcolabor nisiut aliquip ex ea commodo consequat duis aute irure dolor in represse.</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
