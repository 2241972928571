import React from 'react';
import { Link } from 'react-router-dom';
import tickimage from '../../assets/img/learning/tick.svg';

const MockInterview = () => {
  return (
    <>
      <section class="services-area service-area services-padding">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-12">
              <div class="section-tittle text-center">
                <h2>Book your free Mock Interview</h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 bg-warning bg-opacity-10 py-4 text-center">
            <h5>Preparing for Software Developer Jobs? Book a free mock test !</h5>
            </div>
          </div>


          <div className='row'>

            <div className='col-12'>

              <div class="container-1280px round-container">
                <div class="round-blocks-contain">
                  <div class="w-layout-grid round-column-grid">
                    <div class="round-block-wapper _1">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa495_laptop-code.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">MERN/MEAN Stack</div>
                      {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                    </div><div class="round-block-wapper _2">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa497_lightbulb-on.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">Java Stack</div>
                      {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                    </div></div><div class="w-layout-grid round-column-grid">
                    <div class="round-block-wapper _3">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa494_atom-simple%201.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">Python Stack</div>
                      {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                    </div>
                    <div class="round-block-wapper _4">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa496_gears.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">PHP Stack</div>
                      {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                    </div>
                  </div>
                </div>
                <div class="round-text-wrapper">
                  {/* <h2 id="test" class="heading-48px round-heading">Get Your dream job</h2> */}
                  <div class="round-check-list-container">
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">For college freshers / experienced</div></div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">1 Hour Mock Interview</div>
                    </div>
                    {/* <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Start from 30 October</div>
                    </div> */}
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">DSA, Coding Skills, Soft Skills, Project Discussion</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Complete Online Mode</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Zero Charges</div>
                    </div>
                    {/* <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Free / Unpaid Internship for 2 months</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Placement Assistance</div>
                    </div> 
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">PPO / Placement Opportunity</div>
                    </div>*/}
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Interview by MNC Professionals / Industry Experts</div>
                    </div>
                    {/* <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Technologies - MERN, JavaScript, React, PHP, Laravel, Wordpress, Mobile Applications, React Native</div>
                    </div> */}
                  </div>

                  <Link to='/learning/registration/mockinterview'><div class="btn btn-primary">
                    <div class="paragraph-18px colored-button-text">Enroll Now</div>
                   
                  </div>
                  </Link>
                  
                </div></div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MockInterview;
