import React, { useEffect, useState } from 'react';
// import blogImage from '../assets/img/blog/single_blog_1.png';
// import commentImage from '../assets/img/blog/comment_2.png';
import { useParams } from 'react-router';
import axios from 'axios';
import { Link } from 'react-router-dom';

import RecentBlogs from './blogsubpages/RecentBlogs';
import BlogCategories from './blogsubpages/BlogCategories';

const BlogDetail = () => {
    // const { blogid } = useParams();
    const blogid = window.localStorage.getItem('blogid');
    // const blogStrct = {
    //     blog: {
    //         category: '',
    //         featuredimage: '',
    //         postheading: '',
    //         content: ''
    //     },
    //     blogcontent: []
    // }
    const [blogData, setBlogData] = useState({});
    const [categories, setcategories] = useState([{ name: '', displayname: '', count: 0 }, { name: '', displayname: '', count: 0 }]);
    const [recentPosts, setRecentPosts] = useState([]);

    const fetchBlogDetail = async (blogid) => {
        //console.log(blogid);
        const url = process.env.REACT_APP_API_URL + 'blog/fetchblogdetail/' + blogid;
        try {
            const response = await axios.get(url);
            //console.log(response.data[0]);
            //console.log(response.data);
            setBlogData(response.data)
        }
        catch (err) { console.log('Error In API Call - ', err) }
    }

    const fetchCategories = async () => {
        const url = process.env.REACT_APP_API_URL + 'category/fetchallcategories';
        try {
            const response = await axios.get(url);
            //console.log(response.data);
            setcategories(response.data)
        }
        catch (err) { console.log('Error In API Call - ', err) }
    }

    const fetchRecentPosts = async () => {
        const url = process.env.REACT_APP_API_URL + 'blog/recentblogs';
        try {
            const response = await axios.get(url);
            //console.log(response.data);
            setRecentPosts(response.data)
        }
        catch (err) { console.log('Error In API Call - ', err) }
    }

    useEffect(() => {
        fetchBlogDetail(blogid);
        fetchCategories();
        fetchRecentPosts();
    }, [])

    useEffect(() => {
        fetchBlogDetail(blogid);
    }, [window.location.pathname])

    useEffect(() => {
        //console.log('categories - ', categories);
    })

    return (
        <>
            <div className="services-area">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-tittle text-center mb-80">
                                <h2>{blogData.blog && blogData.blog.postheading}​</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog_area single-post-area section-paddingr">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 posts-list">
                            <div className="single-post">
                                {
                                    blogData.blog && (
                                        <div className="feature-img">
                                            {/* <img className="img-fluid" src={blogImage} alt="" /> */}
                                            {/* <img src={require(`../assets/img/blog/articles/${blogData.blog.featuredimage}`)} className="img-fluid" alt="post" /> */}
                                            {/* <img src={`../assets/img/blog/articles/${blogData.blog.featuredimage}`} className="img-fluid" alt="Post Image" /> */}
                                        </div>
                                    )
                                }
                                <div className="blog_details">
                                    <h2>{blogData.blog && blogData.blog.postheading}
                                    </h2>
                                    {/* <ul className="blog-info-link mt-3 mb-4">
                                        <span>{blogData.blog.category}</span>
                                    </ul> */}
                                    {blogData.blog && <div dangerouslySetInnerHTML={{ __html: blogData.blog.content }} />}

                                    {/* {
                                        blogData.blogcontent && blogData.blogcontent.map((item, index) => {
                                            return (
                                                <>
                                                    {
                                                        (item.postcontenttype === 'text') && (<p>{item.postcontent}</p>)
                                                    }
                                                    {
                                                        (item.postcontenttype === 'image') && (<img src={process.env.REACT_APP_IMAGE_URL + item.postcontent} alt='image' className='img-fluid' />)
                                                    }
                                                </>
                                            )
                                        }
                                        )
                                    } */}

                                    {/* <div className="quote-wrapper">
                                        <div className="quotes">
                                            MCSE boot camps have its supporters and its detractors. Some people do not understand why you
                                            should have to spend money on boot camp when you can get the MCSE study materials yourself at
                                            a fraction of the camp price. However, who has the willpower to actually sit through a
                                            self-imposed MCSE training.
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                            {/* <div className="navigation-top">
                                <div className="d-sm-flex justify-content-between text-center">
                                    <p className="like-info"><span className="align-middle"><i className="fa fa-heart"></i></span> Lily and 4
                                        people like this</p>
                                    <div className="col-sm-4 text-center my-2 my-sm-0">
                                        <p className="comment-count"><span className="align-middle"><i className="fa fa-comment"></i></span> 06 Comments</p>
                                    </div>
                                    <ul className="social-icons">
                                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fab fa-dribbble"></i></a></li>
                                        <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                    </ul>
                                </div>
                                <div className="navigation-area">
                                    <div className="row">
                                        <div
                                            className="col-lg-6 col-md-6 col-12 nav-left flex-row d-flex justify-content-start align-items-center">
                                            <div className="thumb">
                                                <a href="#">
                                                    <img className="img-fluid" src={commentImage} alt="" />
                                                </a>
                                            </div>
                                            <div className="arrow">
                                                <a href="#">
                                                    <span className="lnr text-white ti-arrow-left"></span>
                                                </a>
                                            </div>
                                            <div className="detials">
                                                <p>Prev Post</p>
                                                <a href="#">
                                                    <h4>Space The Final Frontier</h4>
                                                </a>
                                            </div>
                                        </div>
                                        <div
                                            className="col-lg-6 col-md-6 col-12 nav-right flex-row d-flex justify-content-end align-items-center">
                                            <div className="detials">
                                                <p>Next Post</p>
                                                <a href="#">
                                                    <h4>Telescopes 101</h4>
                                                </a>
                                            </div>
                                            <div className="arrow">
                                                <a href="#">
                                                    <span className="lnr text-white ti-arrow-right"></span>
                                                </a>
                                            </div>
                                            <div className="thumb">
                                                <a href="#">
                                                    <img className="img-fluid" src={commentImage} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="blog-author">
                                <div className="media align-items-center">
                                    {/* <img src={} alt="" /> */}
                                    <div className="media-body">
                                        <h4>Article Written By - {blogData.blog && blogData.blog.author}</h4>
                                        {/* <p>Second divided from form fish beast made. Every of seas all gathered use saying you're, he
                                            our dominion twon Second divided from</p> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="comments-area">
                                <h4>05 Comments</h4>
                                <div className="comment-list">
                                    <div className="single-comment justify-content-between d-flex">
                                        <div className="user justify-content-between d-flex">
                                            <div className="thumb">
                                                <img src={commentImage} alt="" />
                                            </div>
                                            <div className="desc">
                                                <p className="comment">
                                                    Multiply sea night grass fourth day sea lesser rule open subdue female fill which them
                                                    Blessed, give fill lesser bearing multiply sea night grass fourth day sea lesser
                                                </p>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <h5>
                                                            <a href="#">Emilly Blunt</a>
                                                        </h5>
                                                        <p className="date">December 4, 2017 at 3:12 pm </p>
                                                    </div>
                                                    <div className="reply-btn">
                                                        <a href="#" className="btn-reply text-uppercase">reply</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="comment-form">
                                <h4>Leave a Reply</h4>
                                <form className="form-contact comment_form" action="#" id="commentForm">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea className="form-control w-100" name="comment" id="comment" cols="30" rows="9"
                                                    placeholder="Write Comment"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control" name="name" id="name" type="text" placeholder="Name" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control" name="email" id="email" type="email" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input className="form-control" name="website" id="website" type="text" placeholder="Website" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="button button-contactForm btn_1 boxed-btn">Send Message</button>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                        <div className="col-lg-4">
                            <div className="blog_right_sidebar">
                                {/* <aside className="single_sidebar_widget search_widget">
                                    <form action="#">
                                        <div className="form-group">
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder='Search Keyword'
                                                    onfocus="this.placeholder = ''" onblur="this.placeholder = 'Search Keyword'" />
                                                <div className="input-group-append">
                                                    <button className="btns" type="button"><i className="ti-search"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn"
                                            type="submit">Search</button>
                                    </form>
                                </aside> */}

                                <BlogCategories categories={categories} />

                                <RecentBlogs recentPosts={recentPosts} />

                                {/* <aside className="single_sidebar_widget post_category_widget">
                                    <h4 className="widget_title">Blog Categories</h4>
                                    <ul className="list cat-list">
                                        {
                                            categories.map((item, index) => (
                                                <li key={index}>
                                                    <Link to={`/blogs/category/${item.name}`} href="#" className="d-flex">
                                                        <p>{item.displayname}</p>
                                                        <p>&nbsp;({item.count})</p>
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </aside>
                                <aside className="single_sidebar_widget popular_post_widget">
                                    <h3 className="widget_title">Recent Posts</h3>
                                    {
                                        recentPosts && recentPosts.map((item, index) => (
                                            <div className="media post_item">
                                                
                                                <div className="media-body">
                                                    <Link to={`/blog/${item.postid}`}>
                                                        <h3>{item.postheading}</h3>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </aside> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BlogDetail;
