import React, { useEffect, useState } from 'react';
import loadImage from '../../assets/img/giphy.gif';

const Verification = () => {
    const [certi, setCerti] = useState('');
    const [certidata, setCertiData] = useState({});

    const [apistatus, setApistatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);

    const verify = async () => {
        //console.log('verification starts........');
        setLoader(true);
        setApistatus(false);

        try {
            const url = process.env.REACT_APP_LMS_API_URL + 'course/verify/' + certi;
            const response = await fetch(url);
            const data = await response.json();
            //console.log(data);
            let userid = await data.userid;

            if (userid) {
                const userdetailsurl = process.env.REACT_APP_LMS_API_URL + 'course/userDetails/' + userid;

                const finalres = await fetch(userdetailsurl);
                const finaldata = await finalres.json();

                setCertiData(finaldata);
                //console.log(finalres)
                setLoader(false);
                setApistatus(true);
            }
            else {
                setLoader(false);
                setApistatus(true);
            }
        }
        catch (err) {
            setLoader(false);
            console.log('Error In API Call - ', err)
        }
    }

    return (
        <div className='bg-secondary bg-opacity-10 py-5 text-center'>
            <div className='py-5'>
                <input type='text' placeholder='Enter certificate number' onChange={(e) => setCerti(e.target.value)} />

                <button className='btn btn-primary' onClick={verify}>Verify</button>
            </div>

            {loader && (
                <div className="loading">
                    <img src={loadImage} alt="Loader" />
                </div>
            )
            }

            {
                certidata.user_id && (
                    <>
                        <div class="alert alert-success" role="alert">
                            Certificate Verified !
                            <br></br><br></br>


                            Name - {certidata.name}
                            <br></br>
                            Address - {certidata.city}
                            <br></br>
                            College – {certidata.college}
                            <br></br>
                            Email - {certidata.email}
                            <br></br> <br></br>


                            Emp. ID – {certidata.employeeid}
                            <br></br>
                            Certificate No - {certidata.certificateid}
                            <br></br> <br></br>

                            Start Date - {certidata.startdate}
                            <br></br>
                            End Date - {certidata.enddate}
                            <br></br>
                        </div>
                    </>
                )
            }

            {
                (apistatus && !certidata.user_id) && (
                    <>
                        Data for this certificate number does not exist.
                    </>
                )
            }

        </div>
    )
}

export default Verification
