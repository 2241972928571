import React from 'react';
import { Link } from 'react-router-dom';
import tickimage from '../../assets/img/learning/tick.svg';
import campus from '../../assets/img/campus.avif';

const CampusAmbassador = () => {
    return (
        <>
            <section class="services-area service-area">
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-12">
                            <div class="section-tittle text-center">
                                <h2>Campus Ambassador Program</h2>
                            </div>
                        </div>
                    </div>

                    {/* <div class="row">
            <div class="col-lg-12 bg-warning bg-opacity-10 py-4 text-center">
                
              <h5>Be HashedBit face at your campus.</h5>
            </div>
          </div> */}
                </div>
            </section>


            {/* <section class="services-area service-area services-padding  bg-home5">
                <div class="container">


                    <div className='row'>

                        <div className='col-12'>
                            <h2>Tasks & Responsibilities</h2></div>

                        <div className='col-3 bg-primary bg-opacity-25 px-2 py-2 rounded'>Promoting Company Events in campus</div>
                        <div className='col-3 bg-warning bg-opacity-25 px-2 py-2 rounded'>Leading and organizing workshops & seminars</div>
                        <div className='col-3 bg-success bg-opacity-25 px-2 py-2 rounded'>Connecting Opportunities with college students</div>
                        <div className='col-3 bg-danger bg-opacity-25 px-2 py-2 rounded'>Connecting TPO & Departments</div>
                    </div>

                </div>
            </section> */}

            <section class="services-area service-area services-padding  bg-home5">
                <div class="container">


                    <div className='row'>

                        <div className='col-12'>
                            <div className='row'>
                            <div className='col-6'>
                            <h2>Tasks & Responsibilities</h2>
                            </div>
                            <div className='col-6 text-end'>
                            <h2>Eligibility & Benefits</h2>
                            </div>
                            </div>
                        

                            <div class="container-1280px round-container">
                            
                                <div class="round-blocks-contain">
                                    
                                    <div class="w-layout-grid round-column-grid">
                                        <div class="round-block-wapper _1">
                                            <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa495_laptop-code.svg" loading="lazy" alt="" class="round-block-icon extra" />
                                            <div class="paragraph-22px round-subheading-text">Promoting Company Events in campus</div>
                                            {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                                        </div><div class="round-block-wapper _2">
                                            <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa497_lightbulb-on.svg" loading="lazy" alt="" class="round-block-icon" />
                                            <div class="paragraph-22px round-subheading-text">Leading and organizing workshops & seminars</div>
                                            {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                                        </div></div><div class="w-layout-grid round-column-grid">
                                        <div class="round-block-wapper _3">
                                            <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa494_atom-simple%201.svg" loading="lazy" alt="" class="round-block-icon" />
                                            <div class="paragraph-22px round-subheading-text">Connecting Opportunities with college students</div>
                                            {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                                        </div>
                                        <div class="round-block-wapper _4">
                                            <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa496_gears.svg" loading="lazy" alt="" class="round-block-icon extra" />
                                            <div class="paragraph-22px round-subheading-text">Connecting TPO & Departments</div>
                                            {/* <div class="paragraph-22px round-point-text">1 month</div> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="round-text-wrapper">
                                    {/* <h2 id="test" class="heading-48px round-heading">Get Your dream job</h2> */}
                                    <div class="round-check-list-container">
                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Eligible - College Students (Any Year/Stream/Branch)</div>
                                        </div>
                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Good communication and networking skills.</div>
                                        </div>

                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Flexible timing / Complete Online</div>
                                        </div>
                                        

                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Free access on HashedBit Learning Resources/Training Programs</div>
                                        </div>
                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Internship Experience and Certificate</div>
                                        </div>
                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Good exposure to Software Industry</div>
                                        </div>
                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Internship and PPO opportunities</div>
                                        </div>
                                        <div class="round-check-list-row">
                                            <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                                            <div class="paragraph-18px impossible-check-list-text">Monetary Benefits and Rewards</div>
                                        </div>
                                    </div>



                                    <Link to='/learning/registration/campusambassador'>
                                        <div class="colored-button-inner btn btn-primary">
                                            <div class="paragraph-18px colored-button-text">
                                                Enroll for Campus Ambassador Program
                                            </div>
                                        </div>
                                    </Link>

                                    

                                </div></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CampusAmbassador;
